import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './PingEventSource_FormProperties'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'

const { form: { CngAddForm } } = components

function AddPage({ history, showNotification }) {

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <FormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            create={{
                url: PingEventSourceApiUrls.POST,
                successRedirect: PingPathMap.PING_ADMIN_EVENT_SOURCE_LIST_VIEW
            }}
        />
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default AddPage