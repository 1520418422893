import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({
		sourceEventCode: Yup.string().max(10),
		mappedEventCode: Yup.string().nullable().required(),
		eventSourceCode: Yup.string().nullable().required()
	})
}

export default makeValidationSchema
