import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import Placeholder from './Placeholder'
import PingEventHubLogs_CurrentSubscriptionTable from './PingEventHubLogs_CurrentSubscriptionTable'
import PingEventHubLogs_PullApiLogTable from './PingEventHubLogs_PullApiLogTable'
import PingEventHubLogs_SubscribeApiLogTable from './PingEventHubLogs_SubscribeApiLogTable'
import PingEventHubLogs_BatchLogTable from './PingEventHubLogs_BatchLogTable'
import PingEventHubLogs_PushApiLogTable from './PingEventHubLogs_PushApiLogTable'

const {
	CngTabs
} = components

function MainPage(props) {

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.PING)

	const PingTabKeys = PingKeys.TAB_NAMES

	const Labels = {

		currentSubscriptions: translate(Namespace.PING, PingTabKeys.CURRENT_SUBSCRIPTIONS),
		pullApiLog: translate(Namespace.PING, PingTabKeys.PULL_API_LOG),
		subscribeApiLog: translate(Namespace.PING, PingTabKeys.SUBSCRIBE_API_LOG),
		batchLog: translate(Namespace.PING, PingTabKeys.BATCH_LOG),
		pushApiLog: translate(Namespace.PING, PingTabKeys.PUSH_API_LOG)
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: Labels.currentSubscriptions,
							tabContent: (
								<PingEventHubLogs_CurrentSubscriptionTable {...props} />
							)
						},
						{
							tabName: Labels.pullApiLog,
							tabContent: (
								<PingEventHubLogs_PullApiLogTable {...props} />
							)
						},
						{
							tabName: Labels.subscribeApiLog,
							tabContent: (
								<PingEventHubLogs_SubscribeApiLogTable {...props} />
							)
						},
						{
							tabName: Labels.batchLog,
							tabContent: (
								<PingEventHubLogs_BatchLogTable {...props} />
							)
						},
						{
							tabName: Labels.pushApiLog,
							tabContent: (
								<PingEventHubLogs_PushApiLogTable {...props} />
							)
						}
					]}
				/>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default MainPage