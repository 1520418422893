import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingEventCodeMapping_MakeValidationSchema'

import PingEventCodeApiUrls from 'src/apiUrls/ping/PingEventCodeApiUrls'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'

const {
	form: { field: { CngTextField, CngSwitchField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	eventSourceCode: "",
	sourceEventCode: "",
	sourceEventName: "",
	mappedEventCode: "",
	active: false,
	remarks: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let eventCodeMapping = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_MAPPING);

		let eventSourceCode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_SOURCE_CODE)
		let sourceEventCode = translate(Namespace.PING, PingKeys.FIELDS.SOURCE_EVENT_CODE)
		let sourceEventName = translate(Namespace.PING, PingKeys.FIELDS.SOURCE_EVENT_NAME)
		let mappedEventCode = translate(Namespace.PING, PingKeys.FIELDS.MAPPED_EVENT_CODE)
		let active = translate(Namespace.PING, PingKeys.FIELDS.ACTIVE)
		let remarks = translate(Namespace.PING, PingKeys.FIELDS.REMARKS)

		return {
			eventCodeMapping,
			eventSourceCode,
			sourceEventCode,
			sourceEventName,
			mappedEventCode,
			active,
			remarks
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sourceEventCode}>
								<CngTextField name="sourceEventCode"
									label={translatedTextsObject.sourceEventCode}
									disabled={disabled}
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sourceEventName}>
								<CngTextField name="sourceEventName"
									label={translatedTextsObject.sourceEventName}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mappedEventCode}>
								<CngLookupAutocompleteField name="mappedEventCode"
									label={translatedTextsObject.mappedEventCode}
									disabled={disabled}
									required
									lookupProps={{
										url: PingEventCodeApiUrls.GET,
										label: (record) => {
											return `(${record.code}) ${record.descriptionEN}`
										},
										value: 'code'
									}}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventSourceCode}>
								<CngLookupAutocompleteField name="eventSourceCode"
									label={translatedTextsObject.eventSourceCode}
									disabled={disabled}
									required
									lookupProps={{
										url: PingEventSourceApiUrls.GET,
										label: (record) => {
											return `(${record.code}) ${record.name}`
										},
										value: 'code'
									}}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
								<CngSwitchField name='active'
									label={translatedTextsObject.active}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
								<CngTextField name="remarks"
									label={translatedTextsObject.remarks}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>

						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
