import React from 'react'
import { Card, Divider, CardContent, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventCodeLinkApiUrls from 'src/apiUrls/ping/PingEventCodeLinkApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.UI_COMPONENT, Namespace.PING)

	let PingFieldKeys = PingKeys.FIELDS

	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_LINK)
	let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

	const Labels = {
		TABLE_TITLE: tableTitle,
		PLANNED_EVENT_CODE: translate(Namespace.PING, PingFieldKeys.PLANNED_EVENT_CODE),
		ESTIMATED_EVENT_CODE: translate(Namespace.PING, PingFieldKeys.ESTIMATED_EVENT_CODE),
		ACTUAL_EVENT_CODE: translate(Namespace.PING, PingFieldKeys.ACTUAL_EVENT_CODE),
		ACTIVE: translate(Namespace.PING, PingFieldKeys.ACTIVE),
		REMARKS: translate(Namespace.PING, PingFieldKeys.REMARKS)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'plannedEventCode', title: Labels.PLANNED_EVENT_CODE },
		{ field: 'estimatedEventCode', title: Labels.ESTIMATED_EVENT_CODE },
		{ field: 'actualEventCode', title: Labels.ACTUAL_EVENT_CODE },
		{ field: 'active', title: Labels.ACTIVE, type: 'boolean' },
		{ field: 'remarks', title: Labels.REMARKS, sorting: false }
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.TABLE_TITLE} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingEventCodeLinkApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// add route
							addRoute={`${pathname}/add`}

							// edit route
							editRoute={`${pathname}/edit`}

							// delete
							del={{ url: PingEventCodeLinkApiUrls.DELETE }}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
