import React, {useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NotificationTriggerKeys from 'src/constants/locale/key/NotificationTrigger'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  eventId: 0,
  firstOption: "",
  secondOption: "",
  thirdOption: "",
  forthOption: "",
  fifthOption: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NOTIFICATION_TRIGGER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [eventId] = useField('eventId');

  const [polOptions, setPolOptions] = useState([
    { text: 'Colombo', value: 'COLOMBO' },
    { text: 'China', value: 'CHINA' },
    { text: 'Singapore', value: 'SINGAPORE' },
  ]);

  const [appOptions, setAppOptions] = useState([
    { text: 'COMMON_SERVICE', value: 'COMMON_SERVICE' },
    { text: 'FRAMEWORK', value: 'FRAMEWORK' }
  ]);

  function makeTranslatedTextsObject() {
    let notificationTrigger = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.TITLE
    )
    let eventIdLbl = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.EVENT_ID
    )
    let firstOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FIRST_OPTION
    )
    let secondOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.SECOND_OPTION
    )
    let thirdOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.THIRD_OPTION
    )
    let forthOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FORTH_OPTION
    )
    let fifthOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FIFTH_OPTION
    )

    return {
      notificationTrigger,
      eventIdLbl,
      firstOption,
      secondOption,
      thirdOption,
      forthOption,
      fifthOption
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.notificationTrigger} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventId}>
            <CngSelectField
              name="eventId"
              type="number"
              label="Notification Event"
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_NOTIFICATION_ORIGIN_URL}/notification/event/get`,
                  textAccessor: "eventDescription",
                  valueAccessor: "eventCode",
                  body: {
                    id: eventId
                  }
                }
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstOption}>
            <CngSelectField
              name="firstOption"
              label="Port Of Loading"
              disabled={disabled}
              items={polOptions}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondOption}>
            <CngTextField
              name="secondOption"
              label='SQL script'
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.thirdOption}>
            <CngTextField
              name="thirdOption"
              label='Secret'
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.forthOption}>
            <CngSelectField
              name="forthOption"
              label='Application Name'
              disabled={disabled}
              items={appOptions}
            />
          </CngGridItem>
          {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fifthOption}>
            <CngTextField
              name="fifthOption"
              label={translatedTextsObject.fifthOption}
              disabled={disabled}
            />
          </CngGridItem>*/}
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  return  DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
