import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingSearchLogApiUrls from 'src/apiUrls/ping/PingSearchLogApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, DateTimeRangeFilter, useDefaultNotification, useFetchCustomLookup }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)
	const fetchCustomLookup = useFetchCustomLookup();

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.UI_COMPONENT, Namespace.PING)

	const PingFieldKeys = PingKeys.FIELDS

	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SEARCH_LOG)
	let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

	const Labels = {

		tableTitle: tableTitle,

		// Fields
		userLoginId: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
		searchResultType: translate(Namespace.PING, PingFieldKeys.TYPE),
		searchReferenceType: translate(Namespace.PING, PingFieldKeys.REFERENCE_TYPE),
		searchValue: translate(Namespace.PING, PingFieldKeys.VALUE),
		searchDate: translate(Namespace.PING, PingFieldKeys.DATE),
		searchStatus: translate(Namespace.PING, PingFieldKeys.STATUS)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{
			field: 'userId', title: Labels.userLoginId,
			customLookup: () => {
				return fetchCustomLookup(
					PingSearchLogApiUrls.USER_DETAIL_GET,
					{},
					'content',
					'userProfile.loginId', // label accessor
					'id', // value accessor 
					(error) => console.error(error)
				)
			}
		},
		{
			field: 'searchResultType', title: Labels.searchResultType,
			lookup: {
				INFO: "INFO",
				MILESTONE: "MILESTONE"
			},
		},
		{
			field: 'searchReferenceType', title: Labels.searchReferenceType,
			lookup: {
				FREIGHT_BOOKING_REF: "FREIGHT_BOOKING_REF",
				BL_NUM: "BL_NUM",
				CONTAINER_NUM: "CONTAINER_NUM",
				BARGE_BOOKING_REF: "BARGE_BOOKING_REF"
			}
		},
		{ field: 'searchValue', title: Labels.searchValue },
		{
			field: 'searchDate', title: Labels.searchDate,
			type: "datetime", defaultSort: 'desc',
			filterComponent: DateTimeRangeFilter
		},
		{
			field: 'searchStatus', title: Labels.searchStatus,
			lookup: {
				OK: "OK",
				ERROR: "ERROR"
			}
		}
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.tableTitle} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingSearchLogApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
