import NotificationSubscriptionApiUrls from 'src/apiUrls/NotificationSubscriptionApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Notification'
import { useHistory } from 'react-router-dom'

const {
  form: { CngAddForm }
} = components

function AddPage({ showNotification }) {

  const history = useHistory();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
              url: NotificationSubscriptionApiUrls.POST,
              successRedirect: pathMap.NOTIFICATION_SUBSCRIPTION_LIST_VIEW,
              onPostSubmitSuccess: () => {
                  history.push(pathMap.NOTIFICATION_SUBSCRIPTION_LIST_VIEW)
              }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
