import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import PingEventCodeApiUrls from 'src/apiUrls/ping/PingEventCodeApiUrls'
import makeValidationSchema from './PingEventCodeLink_MakeValidationSchema'

const {
	form: { field: { CngTextField, CngSwitchField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	plannedEventCode: "",
	estimatedEventCode: "",
	actualEventCode: "",
	active: false,
	remarks: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let eventCodeLink = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_LINK);

		let plannedEventCode = translate(Namespace.PING, PingKeys.FIELDS.PLANNED_EVENT_CODE)
		let estimatedEventCode = translate(Namespace.PING, PingKeys.FIELDS.ESTIMATED_EVENT_CODE)
		let actualEventCode = translate(Namespace.PING, PingKeys.FIELDS.ACTUAL_EVENT_CODE)
		let active = translate(Namespace.PING, PingKeys.FIELDS.ACTIVE)
		let remarks = translate(Namespace.PING, PingKeys.FIELDS.REMARKS)

		return {
			eventCodeLink,
			plannedEventCode,
			estimatedEventCode,
			actualEventCode,
			active,
			remarks
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.plannedEventCode}>
								<CngLookupAutocompleteField name="plannedEventCode"
									label={translatedTextsObject.plannedEventCode}
									disabled={disabled}
									lookupProps={{
										url: PingEventCodeApiUrls.GET,
										label: (record) => {
											return `(${record.code}) ${record.descriptionEN}`
										},
										value: 'code'
									}}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.estimatedEventCode}>
								<CngLookupAutocompleteField name="estimatedEventCode"
									label={translatedTextsObject.estimatedEventCode}
									disabled={disabled}
									lookupProps={{
										url: PingEventCodeApiUrls.GET,
										label: (record) => {
											return `(${record.code}) ${record.descriptionEN}`
										},
										value: 'code'
									}}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.actualEventCode}>
								<CngLookupAutocompleteField name="actualEventCode"
									label={translatedTextsObject.actualEventCode}
									disabled={disabled}
									lookupProps={{
										url: PingEventCodeApiUrls.GET,
										label: (record) => {
											return `(${record.code}) ${record.descriptionEN}`
										},
										value: 'code'
									}}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
								<CngSwitchField name='active'
									label={translatedTextsObject.active}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
								<CngTextField name="remarks"
									label={translatedTextsObject.remarks}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>

						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
