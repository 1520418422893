import React from 'react'
import { useParams } from 'react-router-dom'
import { components } from 'cng-web-lib'

import FormProperties from './PingSysConfig_FormProperties'
import PingSysConfigApiUrls from 'src/apiUrls/ping/PingSysConfigApiUrls'

const { form: { CngEditForm } } = components

function EditPage({ history, showNotification }) {

	const { id } = useParams()

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<CngEditForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<FormProperties.Fields
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{ url: PingSysConfigApiUrls.GET }}
			update={{ url: PingSysConfigApiUrls.PUT }}
			id={id}
		/>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default EditPage