import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardHeader, CardContent, Divider } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import EventIdViewForm from './PingShipmentEvent_EventId_ViewForm'
import EditForm from './PingShipmentEvent_EditForm'
import EventDetailTable from './PingShipmentEventDetail_Table'

const {
	card: { CngSimpleCardHeader },
	CngTabs, CardBody
} = components

function EditPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.PING])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let shipmentEvent = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SHIPMENT_EVENT);

		let eventInfoTab = translate(Namespace.PING, PingKeys.FIELDS.EVENT_INFO_TAB_TITLE)
		let eventDetailsTab = translate(Namespace.PING, PingKeys.FIELDS.EVENT_DETAILS_TAB_TITLE)

		return {
			shipmentEvent,
			eventInfoTab,
			eventDetailsTab
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.shipmentEvent} />
					<Divider />
					<CardContent>
						<EventIdViewForm
							showNotification={showNotification}
							id={id}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextsObject.eventInfoTab,
							tabContent: (
								<EditForm
									showNotification={showNotification}
									id={id}
								/>
							)
						},
						{
							tabName: translatedTextsObject.eventDetailsTab,
							tabContent: (
								<EventDetailTable
									showNotification={showNotification}
									eventId={id}
									isViewOnly={false}
								/>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default EditPage