import React from 'react'
import { Grid } from '@material-ui/core'
import { components, Yup } from 'cng-web-lib'

const {
    form: { field: { CngTextField, CngDateTimeField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    remarks: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
    return Yup.object({
    })
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={12} md={6}>
                <CngTextField name="id"
                    label='ID'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngDateTimeField name="createdDate"
                    label='Created Date'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngDateTimeField name="startDate"
                    label='Start Date'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngDateTimeField name="endDate"
                    label='End Date'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngTextField name="elapsedTime"
                    label='Elapsed Time'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12}>
                <CngTextField name="summary"
                    label='Summary'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={6} md={3}>
                <CngTextField name="successCount"
                    label='Success'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={6} md={3}>
                <CngTextField name="errorCount"
                    label='Error'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={6} md={3}>
                <CngTextField name="warningCount"
                    label='Warning'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={6} md={3}>
                <CngTextField name="totalCount"
                    label='Total'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12}>
                <CngTextField name="batchLog"
                    label='Batch Log'
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>
        </Grid>

        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
