import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingEventCode_MakeValidationSchema'

const {
	card: { CngSimpleCardHeader },
	form: { field: { CngTextField, CngSelectField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	code: "",
	eventType: null,
	eventLocodeFrom: null,
	remarks: "",
	descriptionAR: "",
	descriptionEN: "",
	descriptionES: "",
	descriptionFR: "",
	descriptionHI: "",
	descriptionNL: "",
	descriptionPT: "",
	descriptionRU: "",
	descriptionZH: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let eventCodeMaster = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_MASTER);

		let code = translate(Namespace.PING, PingKeys.FIELDS.CODE)
		let eventType = translate(Namespace.PING, PingKeys.FIELDS.EVENT_TYPE)
		let eventLocodeFrom = translate(Namespace.PING, PingKeys.FIELDS.EVENT_LOCODE_FROM)
		let remarks = translate(Namespace.PING, PingKeys.FIELDS.REMARKS)

		let descriptions = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTIONS)
		let description_ar = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_AR)
		let description_en = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_EN)
		let description_es = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_ES)
		let description_fr = translate(Namespace.PING, PingKeys.FIELDS.DESCRPITION_FR)
		let description_hi = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_HI)
		let description_nl = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_NL)
		let description_pt = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_PT)
		let description_ru = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_RU)
		let description_zh = translate(Namespace.PING, PingKeys.FIELDS.DESCRIPTION_ZH)

		return {
			eventCodeMaster,
			code,
			eventType,
			eventLocodeFrom,
			remarks,
			descriptions,
			description_ar,
			description_en,
			description_es,
			description_fr,
			description_hi,
			description_nl,
			description_pt,
			description_ru,
			description_zh
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.eventCodeMaster} />
					<Divider />
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.code}>
								<CngTextField name="code"
									label={translatedTextsObject.code}
									disabled={disabled}
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventType}>
								<CngSelectField name="eventType"
									label={translatedTextsObject.eventType}
									disabled={disabled}
									required
									items={[
										{ text: 'Information', value: 'INFORMATION' },
										{ text: 'Transport', value: 'TRANSPORT' },
										{ text: 'Equipment', value: 'EQUIPMENT' }
									]}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventLocodeFrom}>
								<CngSelectField name="eventLocodeFrom"
									label={translatedTextsObject.eventLocodeFrom}
									disabled={disabled}
									items={[
										{ text: 'Origin', value: 'ORIGIN' },
										{ text: 'Destination', value: 'DESTINATION' }
									]}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
								<CngTextField name="remarks"
									label={translatedTextsObject.remarks}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.descriptions} />
					<Divider />
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_ar}>
								<CngTextField name="descriptionAR"
									label={translatedTextsObject.description_ar}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_zh}>
								<CngTextField name="descriptionZH"
									label={translatedTextsObject.description_zh}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_nl}>
								<CngTextField name="descriptionNL"
									label={translatedTextsObject.description_nl}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_en}>
								<CngTextField name="descriptionEN"
									label={translatedTextsObject.description_en}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_fr}>
								<CngTextField name="descriptionFR"
									label={translatedTextsObject.description_fr}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_hi}>
								<CngTextField name="descriptionHI"
									label={translatedTextsObject.description_hi}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_pt}>
								<CngTextField name="descriptionPT"
									label={translatedTextsObject.description_pt}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_ru}>
								<CngTextField name="descriptionRU"
									label={translatedTextsObject.description_ru}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description_es}>
								<CngTextField name="descriptionES"
									label={translatedTextsObject.description_es}
									disabled={disabled}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
