import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './PingPartyConfig_PartySummaryFormProperties'
import PingPartyConfigApiUrls from 'src/apiUrls/ping/PingPartyConfigApiUrls'

const { form: { CngViewForm } } = components

function ViewForm({ history, showNotification, id }) {

	return (

		//* Start JSX ----------------------------------------------------------
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<FormProperties.Fields
					disabled={true}
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{
				url: PingPartyConfigApiUrls.GET_FROM_TPR_PARTY,
				id: id
			}}
		/>
		//* End JSX ------------------------------------------------------------
	)

}

export default ViewForm
