import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingPortnetLogsApiUrls from 'src/apiUrls/ping/PingPortnetLogsApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'
import FormProperties from './PingPortnetLogs_VesselApiLogFormProperties'

const {
    table: { CngServerModeDialogFormTable, DateTimeRangeFilter, useFetchCustomLookup }
} = components

function TablePage(props) {

    const { showNotification } = props
    const fetchCustomLookup = useFetchCustomLookup()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {
        VESSEL_NAME: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        IN_VOYAGE_NUM: translate(Namespace.PING, PingFieldKeys.IN_VOYAGE_NUM),
        OUT_VOYAGE_NUM: translate(Namespace.PING, PingFieldKeys.OUT_VOYAGE_NUM),
        USER_LOGIN_ID: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
        PARTY: translate(Namespace.PING, PingFieldKeys.PARTY),
        REQUEST_SUMMARY: translate(Namespace.PING, PingFieldKeys.REQUEST_SUMMARY),
        RESPONSE_STATUS: translate(Namespace.PING, PingFieldKeys.RESPONSE_STATUS),
        RESPONSE_SUMMARY: translate(Namespace.PING, PingFieldKeys.RESPONSE_SUMMARY),
        CREATED_DATE: translate(Namespace.PING, PingFieldKeys.CREATED_DATE)
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'vesselName', title: Labels.VESSEL_NAME },
        { field: 'inVoyageNum', title: Labels.IN_VOYAGE_NUM },
        { field: 'outVoyageNum', title: Labels.OUT_VOYAGE_NUM },
        { field: 'userLoginId', title: Labels.USER_LOGIN_ID },
        {
            field: 'partyId', title: Labels.PARTY,
            customLookup: () => {
                return fetchCustomLookup(
                    PingPortnetLogsApiUrls.TPR_PARTY_GET,
                    {},
                    'content',
                    'name',
                    'id',
                    (errorMessage) => console.error(errorMessage)
                )
            }
        },
        { field: 'requestSummary', title: Labels.REQUEST_SUMMARY },
        { field: 'responseStatus', title: Labels.RESPONSE_STATUS },
        { field: 'responseSummary', title: Labels.RESPONSE_SUMMARY },
        {
            field: 'createdDate', title: Labels.CREATED_DATE,
            type: "datetime", defaultSort: 'desc',
            filterComponent: DateTimeRangeFilter
        }
    ];

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'

                    showNotification={showNotification}
                    tableColumns={columns}

                    formProperties={FormProperties}

                    fetch={{ url: PingPortnetLogsApiUrls.VESSEL_API_LOG_GET }}
                    idAccessor='id'

                    disabled={true} // To remove the create/edit/delete buttons.
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
