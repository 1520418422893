import React from 'react'
import { useFormContext } from "react-hook-form";
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingEventSource_MakeValidationSchema'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'

import WarningAlert from 'src/components/alert/WarningAlert'

const { EQUAL } = constants.filter
const {
	form: { field: { CngTextField, CngSwitchField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	code: "",
	parentCode: "",
	name: "",
	displayName: "",
	active: false,
	priority: 0,
	remarks: "",
	system: false
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, id }) {

	const { getValues } = useFormContext();

	// For Add form, will default into FALSE.
	// For Edit form, this value can be used for changing the required fields.
	const isSystemSource = getValues('system')

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.PING)

	const PingFieldKeys = PingKeys.FIELDS
	const Labels = {
		CODE: translate(Namespace.PING, PingFieldKeys.CODE),
		PARENT: translate(Namespace.PING, PingFieldKeys.PARENT),
		SYSTEM: translate(Namespace.PING, PingFieldKeys.SYSTEM),
		NAME: translate(Namespace.PING, PingFieldKeys.NAME),
		DISPLAY_NAME: translate(Namespace.PING, PingFieldKeys.DISPLAY_NAME),
		ACTIVE: translate(Namespace.PING, PingFieldKeys.ACTIVE),
		PRIORITY: translate(Namespace.PING, PingFieldKeys.PRIORITY),
		REMARKS: translate(Namespace.PING, PingFieldKeys.REMARKS)
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						{
							// Show alert when this is a System Source.
							(isSystemSource == true) &&

							<Grid item xs={12}>
								<WarningAlert title="This is a System Event Source!">
									Some of the fields will not be editable.
								</WarningAlert>
							</Grid>
						}

						<Grid container spacing={3}>
							<CngGridItem xs={12} sm={6}>
								<CngTextField name="code"
									label={Labels.CODE}

									// Disable edit for System Source.
									disabled={disabled || (isSystemSource == true)}
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} xs={6}>
								<CngLookupAutocompleteField
									name='parentCode'
									label={Labels.PARENT}
									lookupProps={{
										url: PingEventSourceApiUrls.GET,
										filters: [{ field: 'isSystem', operator: EQUAL, value: true }],
										label: 'code',
										value: 'code'
									}}

									// Required field for non-System source <-not required any more
									//required={(isSystemSource == false)}

									// Disable edit for System Source.
									disabled={disabled || (isSystemSource == true)}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6}>
								<CngTextField name="name"
									label={Labels.NAME}
									disabled={disabled}
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6}>
								<CngTextField name="displayName"
									label={Labels.DISPLAY_NAME}
									disabled={disabled}
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6}>
								<CngTextField name="priority"
									label={Labels.PRIORITY}
									disabled={disabled}
									type="number"
									helperText="Higher number = higher priority."
									required
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6}>
								<CngSwitchField name='active'
									label={Labels.ACTIVE}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12}>
								<CngTextField name="remarks"
									label={Labels.REMARKS}
									disabled={disabled}
									multiline={true}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
