import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingShipmentEvent_MakeValidationSchema'

const {
	form: { field: { CngTextField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	eventId: "(System Generated)",
	eventGuid: "(System Generated)"
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let eventId = translate(Namespace.PING, PingKeys.FIELDS.EVENT_ID)
		let eventGuid = translate(Namespace.PING, PingKeys.FIELDS.EVENT_GUID)

		return {
			eventId,
			eventGuid
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventId}>
				<CngTextField name="eventId"
					label={translatedTextsObject.eventId}
					disabled={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventGuid}>
				<CngTextField name="eventGuid"
					label={translatedTextsObject.eventGuid}
					disabled={true}
				/>
			</CngGridItem>

		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
