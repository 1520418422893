import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import SummaryPage from './PingCgMigrationLogs_SummaryPage'
import CgRawDataMainPage from './cg-raw-data/PingCgMigrationLogs_CgRawDataMainPage'
import MilestoneMigrationMainPage from './milestone-migration/PingCgMigrationLogs_MilestoneMigrationMainPage'
import IdPatchMainPage from './id-patch/PingCgMigrationLogs_IdPatchMainPage'

const {
    CngTabs
} = components

function MainPage(props) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngTabs
                    headerColor='primary'
                    tabs={[
                        {
                            tabName: "Summary",
                            tabContent: (
                                <SummaryPage />
                            )
                        },
                        {
                            tabName: "CG Raw Data",
                            tabContent: (
                                <CgRawDataMainPage {...props} />
                            )
                        },
                        {
                            tabName: "Milestone Migration Logs",
                            tabContent: (
                                <MilestoneMigrationMainPage {...props} />
                            )
                        },
                        {
                            tabName: "ID Patch Logs",
                            tabContent: (
                                <IdPatchMainPage {...props} />
                            )
                        }
                    ]}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default MainPage