import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation, Yup } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'

const {
    form: { field: { CngTextField, CngDateTimeField, CngLookupAutocompleteField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    remarks: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
    return Yup.object({
    })
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {

        // Fields
        createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE),
        summary: translate(Namespace.PING, PingFieldKeys.SUMMARY),
        details: translate(Namespace.PING, PingFieldKeys.DETAILS)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.createdDate}>
                <CngDateTimeField name="createdDate"
                    label={Labels.createdDate}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.requestSummary}>
                <CngTextField name="summary"
                    label={Labels.summary}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.requestHeader}>
                <CngTextField name="batchDetails"
                    label={Labels.details}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>
        </Grid>

        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
