import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
	const minNumZeroMessage = 'Must be a number bigger or equal to 0.'
	const minNumOneMessage = 'Must be a number bigger or equal to 1.'

	const maxNumMessage = 'Must be a number smaller or equal to 2147483647.'
	const flexiAlertMaxNumMessage = 'Must be a number smaller or equal to 48.'

	const mustBeNumber = 'Must be a number.'
	const mustBeInteger = 'Must be an integer.'
	
	return Yup.object({
		contLifeCycleStartBuffer: Yup.number(mustBeNumber).integer(mustBeInteger).min(0, minNumZeroMessage).max(2147483647, maxNumMessage),
		contLifeCycleEndBuffer: Yup.number(mustBeNumber).integer(mustBeInteger).min(0, minNumZeroMessage).max(2147483647, maxNumMessage),
		contMilestoneResultCount: Yup.number(mustBeNumber).integer(mustBeInteger).min(1, minNumOneMessage).max(2147483647, maxNumMessage),
		flexiAlertInterval: Yup.number(mustBeNumber)
							.integer(mustBeInteger)
							.min(1, minNumOneMessage)
							.max(48, flexiAlertMaxNumMessage)
							.nullable(),
		flexiAlertBookingActiveDay: Yup.number(mustBeNumber)
									 .integer(mustBeInteger)
	})
}

export default makeValidationSchema
