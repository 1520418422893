import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './PingEventCodeMapping_FormProperties'
import PingEventCodeMappingApiUrls from 'src/apiUrls/ping/PingEventCodeMappingApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'

const { form: { CngAddForm } } = components

function AddPage({ history, showNotification }) {

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<CngAddForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<FormProperties.Fields
					showNotification={showNotification}
				/>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			create={{
				url: PingEventCodeMappingApiUrls.POST,
				successRedirect: PingPathMap.PING_ADMIN_EVENT_CODE_MAPPING_LIST_VIEW
			}}
		/>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default AddPage