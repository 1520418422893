import React from 'react'
import { Card, Divider, CardContent, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventCodeApiUrls from 'src/apiUrls/ping/PingEventCodeApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.UI_COMPONENT, Namespace.PING)

	let PingFieldKeys = PingKeys.FIELDS

	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_CODE_MASTER)
	let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

	const Labels = {
		TABLE_TITLE: tableTitle,
		CODE: translate(Namespace.PING, PingFieldKeys.CODE),
		DESCRIPTION_EN: translate(Namespace.PING, PingFieldKeys.DESCRIPTION_EN),
		EVENT_TYPE: translate(Namespace.PING, PingFieldKeys.EVENT_TYPE),
		EVENT_LOCODE_FROM: translate(Namespace.PING, PingFieldKeys.EVENT_LOCODE_FROM)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'code', title: Labels.CODE },
		{ field: 'descriptionEN', title: Labels.DESCRIPTION_EN },
		{
			field: 'eventType', title: Labels.EVENT_TYPE,
			lookup: {
				INFORMATION: 'Information',
				TRANSPORT: 'Transport',
				EQUIPMENT: 'Equipment'
			},
		},
		{
			field: 'eventLocodeFrom', title: Labels.EVENT_LOCODE_FROM,
			lookup: {
				NULL: '',
				ORIGIN: 'Origin',
				DESTINATION: 'Destination'
			}
		}
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.TABLE_TITLE} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingEventCodeApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// add route
							addRoute={`${pathname}/add`}

							// edit route
							editRoute={`${pathname}/edit`}

							// delete
							del={{ url: PingEventCodeApiUrls.DELETE }}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default TablePage
