import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingShipmentEvent_MakeValidationSchema'

const {
	card: { CngSimpleCardHeader },
	form: { field: { CngTextField, CngSelectField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	name: "",
	value: "",
	eventId: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SHIPMENT_EVENT_DETAIL)
		let name = translate(Namespace.PING, PingKeys.FIELDS.NAME)
		let value = translate(Namespace.PING, PingKeys.FIELDS.VALUE)

		return {
			title,
			name,
			value
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={translatedTextsObject.title} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>

					{ /* hidden field */}
					<CngGridItem shouldHide={true}>
						<CngTextField name="eventId" disabled required />
					</CngGridItem>

					<CngGridItem xs={6} shouldHide={shouldHideMap.name}>
						<CngSelectField name="name"
							label={translatedTextsObject.name}
							disabled={disabled}
							required
							items={[
								{ text: 'PARTY_NAME', value: 'PARTY_NAME' },
								{ text: 'BOOKING_ID', value: 'BOOKING_ID' },
								{ text: 'BOOKING_REF', value: 'BOOKING_REF' },
								{ text: 'BL_NUMBER', value: 'BL_NUMBER' },
								{ text: 'VESSEL_NAME', value: 'VESSEL_NAME' },
								{ text: 'VOYAGE_NUM', value: 'VOYAGE_NUM' }
							]}
						/>
					</CngGridItem>

					<CngGridItem xs={6} shouldHide={shouldHideMap.value}>
						<CngTextField name="value"
							label={translatedTextsObject.value}
							disabled={disabled}
							required
						/>
					</CngGridItem>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
