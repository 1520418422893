import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PingCgMigrationApiUrls from 'src/apiUrls/ping/cg-migration/PingCgMigrationApiUrls'
import FormProperties from './PingCgMigrationLogs_PatchLogFormProperties'

const {
    table: { CngServerModeDialogFormTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'id', title: 'ID', type: 'numeric' },
        { field: 'batchLogId', title: 'Batch Log ID', type: 'numeric' },
        { field: 'shipmentEventGuid', title: 'Shipment Event GUID' },
        { 
            field: 'status', title: 'Status',
            lookup: {
				OK: 'OK',
				ERROR: 'Error',
				WARNING: 'Warning'
			}
        },
        {
            field: 'referenceType', title: 'Ref Type',
            lookup: {
				FB_ID: 'FB_ID',
				SI_ID: 'SI_ID',
				BARGE_ID: 'BARGE_ID'
			}
        },
        { field: 'cgId', title: 'CG ID', type: 'numeric' },
        { field: 'ngId', title: 'NG ID', type: 'numeric' },
        { field: 'summary', title: 'Summary' },
        {
            field: 'createdDate', title: 'Created Date',
            type: "datetime", defaultSort: 'desc',
            filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'

                    showNotification={showNotification}
                    tableColumns={columns}

                    formProperties={FormProperties}

                    fetch={{ url: PingCgMigrationApiUrls.PATCH_LOG_GET }}
                    idAccessor='id'

                    disabled={true} // To remove the create/edit/delete buttons.
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
