import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Ping'
import PingEventHubLogsMainPage from './PingEventHubLogs_MainPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
	const { translate } = useTranslation(Namespace.PING)
	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_ADMIN)

	return (title);
}

//* Wrapped Main Page ----------------------------------------------------------
function WrappedMainPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<PingEventHubLogsMainPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

export {
	WrappedMainPage as MainPage
}