import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingPartyConfig_MakeValidationSchema'

const {
	form: { field: { CngTextField, CngSelectField, CngSwitchField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	contLifeCycleStartBuffer: "",
	contLifeCycleEndBuffer: "",
	contMilestoneResultCount: "",
	eventHubUsage: "DISABLED",
	remarks: "",
	disablePortnetCall:false,
	flexiAlertInterval:"",   
	flexiAlertBookingActiveDay:""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let contLifeCycleStartBuffer = translate(Namespace.PING, PingKeys.FIELDS.CONT_LIFECYCLE_START_BUFFER)
		let contLifeCycleEndBuffer = translate(Namespace.PING, PingKeys.FIELDS.CONT_LIFECYCLE_END_BUFFER)
		let contMilestoneResultCount = translate(Namespace.PING, PingKeys.FIELDS.CONT_MILESOTNE_RESULT_COUNT)
		let eventHubUsage = translate(Namespace.PING, PingKeys.FIELDS.EVENT_HUB_USAGE)
		let remarks = translate(Namespace.PING, PingKeys.FIELDS.REMARKS)
		let disablePortnetCall = translate(Namespace.PING, PingKeys.FIELDS.DISABLE_PORTNET_CALL)
		let flexiAlertInterval = translate(Namespace.PING, PingKeys.FIELDS.FLEXIALERT_INTERVAL)	
		let flexiAlertBookingActiveDay = translate(Namespace.PING, PingKeys.FIELDS.FLEXIALERT_BOOKING_ACTIVE_DAY)	

		return {
			contLifeCycleStartBuffer,
			contLifeCycleEndBuffer,
			contMilestoneResultCount,
			eventHubUsage,
			remarks,
			disablePortnetCall,
			flexiAlertInterval,
			flexiAlertBookingActiveDay
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contLifeCycleStartBuffer}>
				<CngTextField name="contLifeCycleStartBuffer"
					label={translatedTextsObject.contLifeCycleStartBuffer}
					disabled={disabled}
					type="number"
					helperText="Blank = Use System Config."
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contLifeCycleEndBuffer}>
				<CngTextField name="contLifeCycleEndBuffer"
					label={translatedTextsObject.contLifeCycleEndBuffer}
					disabled={disabled}
					type="number"
					helperText="Blank = Use System Config."
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contMilestoneResultCount}>
				<CngTextField name="contMilestoneResultCount"
					label={translatedTextsObject.contMilestoneResultCount}
					disabled={disabled}
					type="number"
					helperText="Blank = Use System Config."
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventHubUsage}>
				<CngSelectField name='eventHubUsage'
					label={translatedTextsObject.eventHubUsage}
					disabled={disabled}
					items={[
						{ text: 'Disabled', value: 'DISABLED' },
						{ text: 'Normal', value: 'NORMAL' },
						{ text: 'Forward Hub', value: 'FORWARD_HUB' }
					]}
				/>
			</CngGridItem>
			<CngGridItem xs={12} sm={6} >
		 		<CngSwitchField name='disablePortnetCall' label={translatedTextsObject.disablePortnetCall} />
      		</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.flexiAlertInterval}>
				<CngTextField name="flexiAlertInterval"
					label={translatedTextsObject.flexiAlertInterval}
					disabled={disabled}
					type="number"
					inputProps={{ maxLength: 2 }}
					helperText="Blank = No FlexiAlert P!NG Subscription. Interval in hours, enter a whole number between 1 to 48."
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.flexiAlertBookingActiveDay}>
				<CngTextField name="flexiAlertBookingActiveDay"
					label={translatedTextsObject.flexiAlertBookingActiveDay}
					disabled={disabled}
					type="number"
					inputProps={{ maxLength: 2 }}
					helperText="Blank = Use System Config. Get P!NG Flexialert for booking Cargo Delivery Date + X days."
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
				<CngTextField name="remarks"
					label={translatedTextsObject.remarks}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>
		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
