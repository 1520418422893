import {
  constants,
  Yup
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  return Yup.object({
    ntfSubId: Yup.number(),
    eventId: Yup.number(),
    partyId: Yup.number(),
    actionFor: Yup.string(),
    allUser: Yup.boolean(),
    ntfType: Yup.string(),
    rulePath: Yup.string(),
    roles: Yup.string(),
    emails: Yup.string(),
    mobileNo: Yup.string(),
    users: Yup.string(),
    templateCode: Yup.string(),
    eventName: Yup.string(),
    ntfSubRule: Yup.array(),
  })
}

export default makeValidationSchema
