import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PingCgMigrationApiUrls from 'src/apiUrls/ping/cg-migration/PingCgMigrationApiUrls'

const {
    table: { CngCrudTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { 
            field: 'id', title: 'ID', type: 'numeric',
            headerStyle: { minWidth: 150 }
        },
        { field: 'dockey', title: 'Dockey' },
        { field: 'partyId', title: 'Party ID', type: 'numeric' },
        { field: 'carrierPartyId', title: 'Carrier Party ID', type: 'numeric' },
        { field: 'bookStatus', title: 'Book Status' },
        { field: 'shipperRef', title: 'Shipper Ref' },
        { field: 'carrierRef', title: 'Carrier Ref' },
        { field: 'forwarderRef', title: 'Forwarder Ref' },
        { field: 'commonRef', title: 'Common Ref' },
        { field: 'polCode', title: 'POL Code' },
        { field: 'podCode', title: 'POD Code' },
        { 
            field: 'polEta', title: 'POL ETA',
            type: 'datetime', filterComponent: DateTimeRangeFilter
        },
        { 
            field: 'polEtd', title: 'POL ETD',
            type: 'datetime', filterComponent: DateTimeRangeFilter
        },
        { 
            field: 'podEta', title: 'POD ETA',
            type: 'datetime', filterComponent: DateTimeRangeFilter
        },
        { 
            field: 'podEtd', title: 'POD ETD',
            type: 'datetime', filterComponent: DateTimeRangeFilter
        },
        { field: 'fhOutboundReqOrderId', title: 'FH Order ID' },
        {
            field: 'createdDate', title: 'Created Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngCrudTable {...props}
                    notification={showNotification}

                    // table fetch and data details
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    fetch={{ url: PingCgMigrationApiUrls.CG_BOOKING_GET }}
                    idAccessor='id'
                    columns={columns}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
