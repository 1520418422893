import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import NotificationTriggerApiUrls from 'src/apiUrls/NotificationTriggerApiUrls'
import React from 'react'
import NotificationTriggerKeys from 'src/constants/locale/key/NotificationTrigger'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.NOTIFICATION_TRIGGER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let notificationTrigger = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: notificationTrigger
      }
    )
    let eventId = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.EVENT_ID
    )
    let firstOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FIRST_OPTION
    )
    let secondOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.SECOND_OPTION
    )
    let thirdOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.THIRD_OPTION
    )
    let forthOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FORTH_OPTION
    )
    let fifthOption = translate(
      Namespace.NOTIFICATION_TRIGGER,
      NotificationTriggerKeys.FIFTH_OPTION
    )

    return {
      notificationTrigger,
      tableTitle,
      eventId,
      firstOption,
      secondOption,
      thirdOption,
      forthOption,
      fifthOption
    }
  }

  const columns = [
    {
      field: "eventId",
      title: "Notification Event",
    },
    {
      field: "firstOption",
      title: "Port Of Loading",
    }/*,
    {
      field: "secondOption",
      title: translatedTextsObject.secondOption,
    },
    {
      field: "thirdOption",
      title: translatedTextsObject.thirdOption,
    },
    {
      field: "forthOption",
      title: translatedTextsObject.forthOption,
    },
    {
      field: "fifthOption",
      title: translatedTextsObject.fifthOption,
    }*/
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: NotificationTriggerApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: NotificationTriggerApiUrls.EXPORT }}
              fetch={{ url: NotificationTriggerApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
