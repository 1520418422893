import makeValidationSchema from './NtfSubRuleMakeValidationSchema'
import { useTranslation, useServices } from 'cng-web-lib'
import React, {useEffect, useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NotificationSubscriptionKeys from 'src/constants/locale/key/NotificationSubscription'
import {Box, Card, CardContent, Chip, Grid} from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import PortSelectAutoComplete from "../../vesselschedule/searchschedule/PortSelectAutoComplete";
import {v4 as uuid} from "uuid";

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
      CngPortAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: 0,
  ntfSubRuleName: "",
  ruleFilter: "",
  ruleValue: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  eventId
}) {
  const { translate } = useTranslation(Namespace.NOTIFICATION_SUBSCRIPTION)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [ruleValueOptions, setRuleValueOptions] = useState();

  const ruleFilterOptions = [
    { text: 'Equal To', value: 'EQUAL_TO' },
    { text: 'Not Equal To', value: 'NOT_EQUAL_TO' },
  ]

  const { fetchRecords } = useServices();
  const [ruleName] = useField('ntfSubRuleName');
  const [ruleValueField] = useField('ruleValue')
  const [notificationRuleName, setNotificationRuleName] = useState();

  const onChangePortsAutoComplete = (value, option) => {
    if (value != null) {
      console.log("######### on change port:["+JSON.stringify(value)+"]");
    }
  }

  //first time form loading, need to set the rule value
  useEffect( () => {
    setNotificationRuleName(ruleName.value);
    updateRuleValues(ruleName.value);
  }, [])

  useEffect( () => {
    updateRuleValues(notificationRuleName);
  }, [notificationRuleName]);

  function updateRuleValues(subscriptionRule) {

    const onSuccess = (response) => {
      const options = response.content.map((record) => {
        return { text: record.text, value: record.value };
      });
      setRuleValueOptions(options);
    }

    const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

    const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

    fetchRecords.execute(
        `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/notification/notification-subscription/hdr/getCustom-v2`,
        { customData: { key: subscriptionRule } },
        onSuccess,
        onError,
        onComplete
    );

  }

  function makeTranslatedTextsObject() {
    let ntfSubRule = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.TITLE
    )
    let ntfSubRuleId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.NTF_SUB_RULE_ID
    )
    let ntfSubRuleName = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.NTF_SUB_RULE_NAME
    )
    let ruleFilter = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.RULE_FILTER
    )
    let ruleValue = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.RULE_VALUE
    )

    return {
      ntfSubRule,
      ntfSubRuleId,
      ntfSubRuleName,
      ruleFilter,
      ruleValue
    }
  }

  function isPorts() {
    if(notificationRuleName === 'PORT_OF_LOADING' || notificationRuleName === "PORT_OF_DISCHARGE" ) {
      return true;
    }
    return false;
  }

  return (
    <Card>
      <CngSimpleCardHeader title='Notification Subscription Rule' />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ntfSubRuleName}>
            <CngSelectField
              name="ntfSubRuleName"
              label='Notification Subscription Rule Name'
              disabled={disabled}
              fetch={{
                url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/notification/notification-subscription/hdr/getCustom`,
                textAccessor: "text",
                valueAccessor: "value",
                body: { key: eventId.value}
              }}
              onChange={(e) => {
                setNotificationRuleName(e.target.value)
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ruleFilter}>
            <CngSelectField
              name="ruleFilter"
              label={translatedTextsObject.ruleFilter}
              disabled={disabled}
              items={ruleFilterOptions}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ruleValue}>
            {
              isPorts() ?
                  <PortSelectAutoComplete
                      name='ruleValue'
                      label={translatedTextsObject.ruleValue}
                      keyRef={ruleValueField.value}
                      onChangeAutoComplete={onChangePortsAutoComplete}
                      disabled={disabled}
                  /> :
                  <CngSelectField
                      name="ruleValue"
                      label={translatedTextsObject.ruleValue}
                      disabled={disabled}
                      items={ruleValueOptions}
                  />
            }

          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const NtfSubRuleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NtfSubRuleFormProperties
