import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PingCgMigrationApiUrls from 'src/apiUrls/ping/cg-migration/PingCgMigrationApiUrls'

const {
    table: { CngCrudTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { 
            field: 'id', title: 'ID', type: 'numeric',
            headerStyle: { minWidth: 150 }
        },
        { field: 'bookingId', title: 'Booking ID', type: 'numeric' },
        { field: 'partyId', title: 'Party ID', type: 'numeric' },
        { field: 'siStatus', title: 'SI Status', type: 'numeric' },
        {
            field: 'issueDate', title: 'Issue Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        },
        {
            field: 'approvedDate', title: 'Approved Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        },
        { field: 'blNum', title: 'BL Num' },
        { field: 'siDeleted', title: 'SI Deleted', type: 'numeric' },
        {
            field: 'deletedDate', title: 'Deleted Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        },        
        {
            field: 'createdDate', title: 'Created Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngCrudTable {...props}
                    notification={showNotification}

                    // table fetch and data details
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    fetch={{ url: PingCgMigrationApiUrls.CG_SHIPPING_INFO_GET }}
                    idAccessor='id'
                    columns={columns}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
