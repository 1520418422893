import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import CgBookingMilestoneTable from './PingCgMigrationLogs_CgBookingMilestoneTable'
import CgBookingTable from './PingCgMigrationLogs_CgBookingTable'
import CgShippingInfoTable from './PingCgMigrationLogs_CgShippingInfoTable'

const {
    CngTabs
} = components

function MainPage(props) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngTabs
                    headerColor='secondary'
                    tabs={[
                        {
                            tabName: "Booking Milestones",
                            tabContent: (
                                <CgBookingMilestoneTable {...props} />
                            )
                        },
                        {
                            tabName: "Booking Info",
                            tabContent: (
                                <CgBookingTable {...props} />
                            )
                        },
                        {
                            tabName: "Shipping Info",
                            tabContent: (
                                <CgShippingInfoTable {...props} />
                            )
                        }
                    ]}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default MainPage