import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NotificationSubscriptionKeys from 'src/constants/locale/key/NotificationSubscription'
import {Card, CardContent, Grid} from '@material-ui/core'
import {components, constants, DataFlattener, useTranslation} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import NtfSubRuleFormProperties from './NtfSubRuleFormProperties'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSwitchField,
      CngSelectField,
    },
  },
  table: {
    CngLocalModeDialogFormTable,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const { CodeMaintenanceType } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: 0,
  eventId: 0,
  partyId: 0,
  actionFor: "",
  allUser: false,
  ntfType: "",
  rulePath: "",
  roles: "",
  emailId: "",
  mobileNo: "",
  userId: "",
  templateCode: "",
  eventName: "",
  ntfSubRule: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const ntfTypeOptions = [
  { text: 'Email(Plain)', value: 'EMAIL_PLAIN' },
  { text: 'Email(Html)', value: 'EMAIL_HTML' }
  // { text: 'SMS', value: 'SMS' },
  // { text: 'Push Notification', value: 'PUSH' }
]

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NOTIFICATION_SUBSCRIPTION)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [partyId] = useField('partyId');
  const [eventId] = useField('eventId');

  function makeTranslatedTextsObject() {
    let notificationSubscription = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.TITLE
    )
    let ntfSubId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NTF_SUB_ID
    )
    let eventIdLbl = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EVENT_ID
    )
    let partyIdLbl = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.PARTY_ID
    )
    let actionFor = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ACTION_FOR
    )
    let allUser = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ALL_USER
    )
    let ntfType = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NTF_TYPE
    )
    let rulePath = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.RULE_PATH
    )
    let rolesLbl = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ROLES
    )
    let emails = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EMAILS
    )
    let mobileNo = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.MOBILE_NO
    )
    let users = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.USERS
    )
    let templateCode = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.TEMPLATE_CODE
    )
    let eventName = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EVENT_NAME
    )
    let ntfSubRule = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.TITLE
    )
    let ntfSubRuleId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.NTF_SUB_RULE_ID
    )
    let ntfSubRuleName = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.NTF_SUB_RULE_NAME
    )
    let ruleFilter = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.RULE_FILTER
    )
    let ruleValue = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NtfSubRule.RULE_VALUE
    )

    return {
      notificationSubscription,
      ntfSubId,
      eventIdLbl,
      partyIdLbl,
      actionFor,
      allUser,
      ntfType,
      rulePath,
      rolesLbl,
      emails,
      mobileNo,
      users,
      templateCode,
      eventName,
      ntfSubRule,
      ntfSubRuleId,
      ntfSubRuleName,
      ruleFilter,
      ruleValue
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.notificationSubscription} />
          <CardContent>
            <Grid container spacing={3}>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
                <CngTextField
                  name="id"
                  type="number"
                  label={translatedTextsObject.ntfSubId}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventId}>
                <CngSelectField
                  name="eventId"
                  label='Event'
                  disabled={disabled}
                  fetch={
                    {
                      url: `${process.env.REACT_APP_NOTIFICATION_ORIGIN_URL}/notification/event/get`,
                      textAccessor: "eventDescription",
                      body: {
                        id: eventId
                      }
                    }
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngSelectField
                  name="partyId"
                  label='Party'
                  disabled={disabled}
                  fetch={
                    {
                      url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                      textAccessor: "name",
                      body: {
                        id: partyId
                      }
                    }
                  }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.actionFor}>
                <CngTextField
                  name="actionFor"
                  label='Event Subscription Name'
                  disabled={disabled}
                />
              </CngGridItem>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.allUser}>
                <CngSwitchField
                  name="allUser"
                  label={translatedTextsObject.allUser}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ntfType}>
                <CngSelectField
                  name="ntfType"
                  label='Notification Type'
                  disabled={disabled}
                  items={ntfTypeOptions}
                />
              </CngGridItem>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.rulePath}>
                <CngTextField
                  name="rulePath"
                  label={translatedTextsObject.rulePath}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.roles}>
                <CngSelectField
                  name="roles"
                  label={translatedTextsObject.roles}
                  disabled={disabled}
                  fetch={
                    {
                      url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/role/get`,
                      textAccessor: "roleDescription",
                      body: {
                        id: roles
                      }
                    }
                  }
                />
              </CngGridItem>*/}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.emails}>
                <CngTextField
                  name="emailId"
                  label={translatedTextsObject.emails}
                  disabled={disabled}
                />
              </CngGridItem>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mobileNo}>
                <CngTextField
                  name="mobileNo"
                  label={translatedTextsObject.mobileNo}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.users}>
                <CngSelectField
                  name="userId"
                  label={translatedTextsObject.users}
                  disabled={disabled}
                  fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`, textAccessor: "loginId"}}
                />
              </CngGridItem>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateCode}>
                <CngTextField
                  name="templateCode"
                  label={translatedTextsObject.templateCode}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventName}>
                <CngTextField
                  name="eventName"
                  label={translatedTextsObject.eventName}
                  disabled={disabled}
                />
              </CngGridItem>*/}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title='Notification Subscription Rule' />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="ntfSubRule"
              tableColumns={[
                /*{
                  title: translatedTextsObject.ntfSubRuleId,
                  field: "id",
                  type: "numeric",
                },*/
                {
                  title: 'Notification Subscription Rule Name',
                  field: "ntfSubRuleName"
                },
                {
                  title: translatedTextsObject.ruleFilter,
                  field: "ruleFilter",
                },
                {
                  title: translatedTextsObject.ruleValue,
                  field: "ruleValue",
                  /*customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                        CodeMaintenanceType.PORT,
                        (errorMessage) => console.log(errorMessage)
                    )
                  }*/
                  /*customLookup: () => {
                    return fetchCustomLookup(
                        `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/notification/notification-subscription/hdr/getCustom-v3`,
                        {},
                        'content',
                        'text',
                        'value',
                        (error) => console.error(error)
                    )
                  }*/
                },
              ]}
              formProperties={ {...NtfSubRuleFormProperties, eventId: eventId} }
              initialData={DEFAULT_INITIAL_VALUES.ntfSubRule}
              isViewOnly={disabled}
              idGenerated
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  return  DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties;
