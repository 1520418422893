import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({
		code: Yup.string().max(50)
			.matches(/^[^\s]*$/, 'Cannot contain space.'),
		name: Yup.string().max(100),
		displayName: Yup.string().max(100),
		priority: Yup.number().integer().min(-2147483647).max(2147483647),
	})
}

export default makeValidationSchema
