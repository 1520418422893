import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({

	})
}

export default makeValidationSchema
