import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, Divider, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import PartySummaryViewForm from './PingPartyConfig_PartySummaryViewForm'
import ViewForm from './PingPartyConfig_ViewForm'

const { card: { CngSimpleCardHeader } } = components

function ViewPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.PING])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_PARTY_CONFIG)
		let partySummarySectionHeader = translate(Namespace.PING, PingKeys.FIELDS.PARTY_SUMMARY)
		let partyConfigSectionHeader = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_PARTY_CONFIG)

		return {
			title,
			partySummarySectionHeader,
			partyConfigSectionHeader
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partySummarySectionHeader} />
					<Divider />
					<CardContent>
						<PartySummaryViewForm
							showNotification={showNotification}
							id={id}
						/>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.partyConfigSectionHeader} />
					<Divider />
					<CardContent>
						<ViewForm 
							showNotification={showNotification}
							id={id}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default ViewPage