import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventHubLogsApiUrls from 'src/apiUrls/ping/PingEventHubLogsApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'
import FormProperties from './PingEventHubLogs_PullApiLogFormProperties'

const {
	table: { CngServerModeDialogFormTable, DateTimeRangeFilter, useFetchCustomLookup }
} = components

function TablePage(props) {

	const { showNotification } = props
	const fetchCustomLookup = useFetchCustomLookup()

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.PING)

	const PingFieldKeys = PingKeys.FIELDS

	const Labels = {

		// Columns
		containerNum: translate(Namespace.PING, PingFieldKeys.CONTAINER_NUM),
		userLoginId: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
		party: translate(Namespace.PING, PingFieldKeys.PARTY),
		apiKey: translate(Namespace.PING, PingFieldKeys.API_KEY),
		requestSummary: translate(Namespace.PING, PingFieldKeys.REQUEST_SUMMARY),
		responseStatus: translate(Namespace.PING, PingFieldKeys.RESPONSE_STATUS),
		responseSummary: translate(Namespace.PING, PingFieldKeys.RESPONSE_SUMMARY),
		createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'containerNum', title: Labels.containerNum },
		{ field: 'userLoginId', title: Labels.userLoginId },
		{
			field: 'partyId', title: Labels.party,
			customLookup: () => {
				return fetchCustomLookup(
					PingEventHubLogsApiUrls.TPR_PARTY_GET,
					{},
					'content',
					'name',
					'id',
					(errorMessage) => console.error(errorMessage)
				)
			}
		},
		{
			field: 'apiKeyUsed', title: Labels.apiKey,
			lookup: {
				CALISTA: 'Calista',
				FORWARD_HUB: 'Forward Hub'
			}
		},
		{ field: 'requestSummary', title: Labels.requestSummary },
		{ field: 'responseStatus', title: Labels.responseStatus },
		{ field: 'responseSummary', title: Labels.responseSummary },
		{
			field: 'createdDate', title: Labels.createdDate,
			type: "datetime", defaultSort: 'desc',
			filterComponent: DateTimeRangeFilter
		}
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngServerModeDialogFormTable
					fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'

					showNotification={showNotification}
					tableColumns={columns}

					formProperties={FormProperties}

					fetch={{ url: PingEventHubLogsApiUrls.PULL_API_LOG_GET }}
					idAccessor='id'

					disabled={true} // To remove the create/edit/delete buttons.
				/>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
