import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingShipmentEventDetailApiUrls from 'src/apiUrls/ping/PingShipmentEventDetailApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'
import FormProperties from './PingShipmentEventDetail_FormProperties'

const { table: { CngServerModeDialogFormTable } } = components

function EventDetailTable({ showNotification, eventId, isViewOnly }) {

	const { translate } = useTranslation([
		Namespace.UI_COMPONENT, Namespace.PING
	])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// columns
		let name = translate(Namespace.PING, PingKeys.FIELDS.NAME)
		let value = translate(Namespace.PING, PingKeys.FIELDS.VALUE)

		return {
			name,
			value
		}
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'name', title: translatedTextsObject.name },
		{ field: 'value', title: translatedTextsObject.value }
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<CngServerModeDialogFormTable
			showNotification={showNotification}
			disabled={isViewOnly}

			// table fetch and data details
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			fetch={{
				url: PingShipmentEventDetailApiUrls.GET,
				body: {
					'eventId': eventId
				}
			}}
			idAccessor='id'
			tableColumns={columns}

			// form properties
			formProperties={{
				...FormProperties,
				formikProps: {
					...FormProperties.formikProps,
					initialValues: {
						...FormProperties.formikProps.initialValues,
						eventId: eventId
					}
				}
			}}
			toClientDataFormat={(serverData) => {
				return serverData
			}}
			toServerDataFormat={(localDatum) => {
				return localDatum
			}}

			// form actions
			create={{ url: PingShipmentEventDetailApiUrls.POST }}
			update={{ url: PingShipmentEventDetailApiUrls.PUT }}
			del={{ url: PingShipmentEventDetailApiUrls.DELETE }}

		/>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default EventDetailTable
