import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent, Divider } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import AddForm from './PingShipmentEvent_AddForm'

const { card: { CngSimpleCardHeader } } = components

function AddPage({ history, showNotification }) {

	const { translate } = useTranslation([Namespace.PING])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let shipmentEvent = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SHIPMENT_EVENT);

		return {
			shipmentEvent
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CngSimpleCardHeader title={translatedTextsObject.shipmentEvent} />
					<Divider />
					<CardContent>
						<AddForm
							history={history}
							showNotification={showNotification}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default AddPage