import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventHubLogsApiUrls from 'src/apiUrls/ping/PingEventHubLogsApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'
import FormProperties from './PingEventHubLogs_PushApiLogFormProperties'

const {
    table: { CngServerModeDialogFormTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {

        // Columns
        apiKey: translate(Namespace.PING, PingFieldKeys.API_KEY),
        requestSummary: translate(Namespace.PING, PingFieldKeys.REQUEST_SUMMARY),
        createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE)
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        {
            field: 'apiKeyUsed', title: Labels.apiKey,
            lookup: {
                CALISTA: 'Calista',
                FORWARD_HUB: 'Forward Hub'
            }
        },
        { field: 'requestSummary', title: Labels.requestSummary },
        {
            field: 'createdDate', title: Labels.createdDate,
            type: "datetime", defaultSort: 'desc',
            filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngServerModeDialogFormTable
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'

                    showNotification={showNotification}
                    tableColumns={columns}

                    formProperties={FormProperties}

                    fetch={{ url: PingEventHubLogsApiUrls.PUSH_API_LOG_GET }}
                    idAccessor='id'

                    disabled={true} // To remove the create/edit/delete buttons.
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
