import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PingCgMigrationApiUrls from 'src/apiUrls/ping/cg-migration/PingCgMigrationApiUrls'

const {
    table: { CngCrudTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { 
            field: 'id', title: 'ID', type: 'numeric',
            headerStyle: { minWidth: 150 }
        },
        {
            field: 'eventDate', title: 'Event Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        },
        { field: 'eventCode', title: 'Event Code' },
        { field: 'eventName', title: 'Event Name' },
        { field: 'eventLocode', title: 'Event Locode' },
        { field: 'eventLocation', title: 'Event Location' },
        { field: 'eventDetails', title: 'Event Details' },
        { field: 'type', title: 'Type' },
        { field: 'source', title: 'Source' },
        { field: 'remarks', title: 'Remarks' },
        { field: 'bookingId', title: 'Booking ID', type: 'numeric' },
        { field: 'bookingRef', title: 'Booking Ref' },
        { field: 'shippingInfoId', title: 'Shipping Info ID', type: 'numeric' },
        { field: 'blNum', title: 'BL Num' },
        { field: 'containerNum', title: 'Container Num' },
        { field: 'vesselName', title: 'Vessel Name' },
        { field: 'partyId', title: 'Party ID', type: 'numeric' },
        { field: 'bargeBookingId', title: 'Barge Booking ID', type: 'numeric' },
        { field: 'bargeBookingRef', title: 'Barge Booking Ref' },
        {
            field: 'createdDate', title: 'Created Date',
            type: "datetime", filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngCrudTable {...props}
                    notification={showNotification}

                    // table fetch and data details
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    fetch={{ url: PingCgMigrationApiUrls.CG_BOOKING_MILESTONE_GET }}
                    idAccessor='id'
                    columns={columns}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
