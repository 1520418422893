import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const { card: { CngSimpleCardHeader }, table: { CngCrudTable, useDefaultNotification } } = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.UI_COMPONENT, Namespace.PING)

	const title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_EVENT_SOURCE)
	const tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE,
		{ nameTitleised: title }
	)

	const PingFieldKeys = PingKeys.FIELDS
	const Labels = {
		TABLE_TITLE: tableTitle,

		CODE: translate(Namespace.PING, PingFieldKeys.CODE),
		SYSTEM: translate(Namespace.PING, PingFieldKeys.SYSTEM),
		PARENT: translate(Namespace.PING, PingFieldKeys.PARENT),
		NAME: translate(Namespace.PING, PingFieldKeys.NAME),
		DISPLAY_NAME: translate(Namespace.PING, PingFieldKeys.DISPLAY_NAME),
		ACTIVE: translate(Namespace.PING, PingFieldKeys.ACTIVE),
		PRIORITY: translate(Namespace.PING, PingFieldKeys.PRIORITY)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'code', title: Labels.CODE },
		{ field: 'isSystem', title: Labels.SYSTEM, type: 'boolean' },
		{ field: 'parentCode', title: Labels.PARENT },
		{ field: 'name', title: Labels.NAME },
		{ field: 'displayName', title: Labels.DISPLAY_NAME },
		{ field: 'active', title: Labels.ACTIVE, type: 'boolean' },
		{ field: 'priority', title: Labels.PRIORITY, type: 'numeric', defaultSort: 'desc', filtering: false }
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.TABLE_TITLE} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingEventSourceApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// add route
							addRoute={`${pathname}/add`}

							// edit route
							editRoute={`${pathname}/edit`}

							// delete
							del={{ url: PingEventSourceApiUrls.DELETE }}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
