import React from 'react'
import { Grid, InputAdornment, Card, CardContent } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingSearchLogApiUrls from 'src/apiUrls/ping/PingSearchLogApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingSearchLog_MakeValidationSchema'

const {
	form: { field: { CngTextField, CngDateTimeField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	userId: "",
	searchResultType: "",
	searchReferenceType: "",
	searchValue: "",
	searchDate: "",
	searchStatus: "",
	searchResult: "",
	elapsedTime: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.PING)

	const PingFieldKeys = PingKeys.FIELDS

	const Labels = {
		userLoginId: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
		searchResultType: translate(Namespace.PING, PingFieldKeys.TYPE),
		searchReferenceType: translate(Namespace.PING, PingFieldKeys.REFERENCE_TYPE),
		searchValue: translate(Namespace.PING, PingFieldKeys.VALUE),
		searchDate: translate(Namespace.PING, PingFieldKeys.DATE),
		searchStatus: translate(Namespace.PING, PingFieldKeys.STATUS),
		searchResult: translate(Namespace.PING, PingFieldKeys.RESULT),
		elapsedTime: translate(Namespace.PING, PingFieldKeys.ELAPSED_TIME)
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>

							<CngGridItem xs={12} shouldHide={shouldHideMap.userId}>
								<CngLookupAutocompleteField name="userId"
									label={Labels.userLoginId}
									disabled={disabled}
									lookupProps={{
										url: PingSearchLogApiUrls.USER_DETAIL_GET,
										label: 'userProfile.loginId',
										value: 'id'
									}}
								/>

							</CngGridItem>

							<CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.searchResultType}>
								<CngTextField name="searchResultType"
									label={Labels.searchResultType}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.searchReferenceType}>
								<CngTextField name="searchReferenceType"
									label={Labels.searchReferenceType}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.searchValue}>
								<CngTextField name="searchValue"
									label={Labels.searchValue}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.searchDate}>
								<CngDateTimeField name="searchDate"
									label={Labels.searchDate}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.searchStatus}>
								<CngTextField name="searchStatus"
									label={Labels.searchStatus}
									disabled={disabled}
								/>
							</CngGridItem>

							<CngGridItem xs={12} shouldHide={shouldHideMap.searchResult}>
								<CngTextField name="searchResult"
									label={Labels.searchResult}
									disabled={disabled}
									multiline={true}
									rowsMax={10}
								/>
							</CngGridItem>

							<CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.elapsedTime}>
								<CngTextField name="elapsedTime"
									label={Labels.elapsedTime}
									disabled={disabled}
									InputProps={{
										endAdornment: (<InputAdornment position="end">ms</InputAdornment>)
									}}
								/>
							</CngGridItem>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>

		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
