import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PatchBatchLogTable from './PingCgMigrationLogs_PatchBatchLogTable'
import PatchLogTable from './PingCgMigrationLogs_PatchLogTable'

const {
    CngTabs
} = components

function MainPage(props) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngTabs
                    headerColor='secondary'
                    tabs={[
                        {
                            tabName: "Batch Logs",
                            tabContent: (
                                <PatchBatchLogTable {...props} />
                            )
                        },
                        {
                            tabName: "Patch Logs",
                            tabContent: (
                                <PatchLogTable {...props} />
                            )
                        }
                    ]}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default MainPage