import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import { useTranslation } from 'cng-web-lib'

const PingTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.PING,
  ])

  const PingFieldKeys = PingKeys.FIELDS
  
  //copy from form and view form 

  let cutoffType = translate(Namespace.PING, PingFieldKeys.CUTOFF_TYPE)
  let cutoffEventType = translate(Namespace.PING, PingFieldKeys.CUTOFF_EVENT_TYPE)
  let instructions= translate(Namespace.PING, PingFieldKeys.INSTRUCTIONS)
  let unit= translate(Namespace.PING, PingFieldKeys.UNIT)
  let maxTemp= translate(Namespace.PING, PingFieldKeys.MAX_TEMP)
  let minTemp= translate(Namespace.PING, PingFieldKeys.MIN_TEMP)
  let sn= translate(Namespace.PING, PingFieldKeys.SN)
 

  
  return {
    cutoffType,
    cutoffEventType,
    instructions,
    unit,
    maxTemp,
    minTemp,
    sn   
  }
}

export default PingTranslationText
