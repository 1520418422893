import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import PingPortnetLogs_VesselApiLogTable from './PingPortnetLogs_VesselApiLogTable'

const {
    CngTabs
} = components

function MainPage(props) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const Labels = {
        VESSEL_API_LOG: translate(Namespace.PING, PingKeys.TAB_NAMES.VESSEL_API_LOG)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngTabs
                    headerColor='primary'
                    tabs={[
                        {
                            tabName: Labels.VESSEL_API_LOG,
                            tabContent: (
                                <PingPortnetLogs_VesselApiLogTable {...props} />
                            )
                        }
                    ]}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default MainPage