import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({
		code: Yup.string().max(10)
	})
}

export default makeValidationSchema
