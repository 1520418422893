import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './PingShipmentEvent_FormProperties'
import PingShipmentEventApiUrls from 'src/apiUrls/ping/PingShipmentEventApiUrls'
import PingPathMap from 'src/paths/PathMap_Ping'

const { form: { CngAddForm } } = components

function AddForm({ history, showNotification }) {
	return (

		//* Start JSX ----------------------------------------------------------
		<CngAddForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<FormProperties.Fields
					showNotification={showNotification}
				/>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			create={{
				url: PingShipmentEventApiUrls.POST,
				onPostSubmitSuccess: (data) => {
					let editUrl = PingPathMap.PING_ADMIN_SHIPMENT_EVENT + "/edit/" + data.id
					history.push(editUrl)
				}
			}}
		/>
		//* End JSX ------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default AddForm
