import React from 'react'
import { components } from 'cng-web-lib'

import FormProperties from './PingShipmentEvent_FormProperties'
import PingShipmentEventApiUrls from 'src/apiUrls/ping/PingShipmentEventApiUrls'

const { form: { CngEditForm } } = components

function EditForm({ history, showNotification, id }) {
	return (

		//* Start JSX ----------------------------------------------------------
		<CngEditForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			history={history}
			showNotification={showNotification}
			bodySection={
				<FormProperties.Fields
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={FormProperties.formikProps}
			toClientDataFormat={FormProperties.toClientDataFormat}
			toServerDataFormat={FormProperties.toServerDataFormat}
			fetch={{ url: PingShipmentEventApiUrls.GET }}
			update={{ url: PingShipmentEventApiUrls.PUT }}
			id={id}
		/>
		//* End JSX ------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default EditForm
