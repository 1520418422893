import NotificationSubscriptionApiUrls from 'src/apiUrls/NotificationSubscriptionApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            id: id,
            url: NotificationSubscriptionApiUrls.GET
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ViewPage
