import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import PingCgMigrationApiUrls from 'src/apiUrls/ping/cg-migration/PingCgMigrationApiUrls'

const {
    table: { CngCrudTable, DateTimeRangeFilter }
} = components

function TablePage(props) {

    const { showNotification } = props

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'milestoneId', title: 'Milestone ID', type: 'numeric' },
        {
            field: 'eventDate', title: 'Event Date',
            type: 'datetime', filterComponent: DateTimeRangeFilter
        }
    ]

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngCrudTable {...props}
                    notification={showNotification}

                    // table fetch and data details
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    fetch={{ url: PingCgMigrationApiUrls.MIGRATION_QUEUE_GET }}
                    idAccessor='id'
                    columns={columns}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
