import React from 'react'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

import MigrationQueueTable from './PingCgMigrationLogs_MigrationQueueTable'
import MigrationBatchLogTable from './PingCgMigrationLogs_MigrationBatchLogTable'
import MigrationLogTable from './PingCgMigrationLogs_MigrationLogTable'

const {
    CngTabs
} = components

function MainPage(props) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngTabs
                    headerColor='secondary'
                    tabs={[
                        {
                            tabName: "Migration Queue",
                            tabContent: (
                                <MigrationQueueTable {...props} />
                            )
                        },
                        {
                            tabName: "Batch Logs",
                            tabContent: (
                                <MigrationBatchLogTable {...props} />
                            )
                        },
                        {
                            tabName: "Migration Logs",
                            tabContent: (
                                <MigrationLogTable {...props} />
                            )
                        }
                    ]}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default MainPage