import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation, Yup } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import PingPortnetLogsApiUrls from 'src/apiUrls/ping/PingPortnetLogsApiUrls'

const {
    form: { field: { CngTextField, CngDateTimeField, CngLookupAutocompleteField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    remarks: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
    return Yup.object({
    })
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {
        VESSEL_NAME: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        IN_VOYAGE_NUM: translate(Namespace.PING, PingFieldKeys.IN_VOYAGE_NUM),
        OUT_VOYAGE_NUM: translate(Namespace.PING, PingFieldKeys.OUT_VOYAGE_NUM),
        USER_LOGIN_ID: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
        PARTY: translate(Namespace.PING, PingFieldKeys.PARTY),
        REQUEST_SUMMARY: translate(Namespace.PING, PingFieldKeys.REQUEST_SUMMARY),
        REQUEST_HEADER: translate(Namespace.PING, PingFieldKeys.REQUEST_HEADER),
        REQUEST_BODY: translate(Namespace.PING, PingFieldKeys.REQUEST_BODY),
        RESPONSE_STATUS: translate(Namespace.PING, PingFieldKeys.RESPONSE_STATUS),
        RESPONSE_SUMMARY: translate(Namespace.PING, PingFieldKeys.RESPONSE_SUMMARY),
        RESPONSE_HEADER: translate(Namespace.PING, PingFieldKeys.RESPONSE_HEADER),
        RESPONSE_BODY: translate(Namespace.PING, PingFieldKeys.RESPONSE_BODY),
        DEBUG_LOG: translate(Namespace.PING, PingFieldKeys.DEBUG_LOG),
        REMARKS: translate(Namespace.PING, PingFieldKeys.REMARKS),
        CREATED_DATE: translate(Namespace.PING, PingFieldKeys.CREATED_DATE)
    }

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.vesselName}>
                <CngTextField name="vesselName"
                    label={Labels.VESSEL_NAME}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.inVoyageNum}>
                <CngTextField name="inVoyageNum"
                    label={Labels.IN_VOYAGE_NUM}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.outVoyageNum}>
                <CngTextField name="outVoyageNum"
                    label={Labels.OUT_VOYAGE_NUM}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.createdDate}>
                <CngDateTimeField name="createdDate"
                    label={Labels.CREATED_DATE}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.userLoginId}>
                <CngTextField name="userLoginId"
                    label={Labels.USER_LOGIN_ID}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.party}>
                <CngLookupAutocompleteField name="partyId"
                    label={Labels.PARTY}
                    disabled={disabled}
                    lookupProps={{
                        url: PingPortnetLogsApiUrls.TPR_PARTY_GET,
                        label: 'name',
                        value: 'id'
                    }}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.requestSummary}>
                <CngTextField name="requestSummary"
                    label={Labels.REQUEST_SUMMARY}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.requestHeader}>
                <CngTextField name="requestHeader"
                    label={Labels.REQUEST_HEADER}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.requestBody}>
                <CngTextField name="requestBody"
                    label={Labels.REQUEST_BODY}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={9} shouldHide={shouldHideMap.responseSummary}>
                <CngTextField name="responseSummary"
                    label={Labels.RESPONSE_SUMMARY}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={3} shouldHide={shouldHideMap.responseStatus}>
                <CngTextField name="responseStatus"
                    label={Labels.RESPONSE_STATUS}
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.responseHeader}>
                <CngTextField name="responseHeader"
                    label={Labels.RESPONSE_HEADER}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.responseBody}>
                <CngTextField name="responseBody"
                    label={Labels.RESPONSE_BODY}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.debugLog}>
                <CngTextField name="debugLog"
                    label={Labels.DEBUG_LOG}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>

            <CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
                <CngTextField name="remarks"
                    label={Labels.REMARKS}
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>
        </Grid>

        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
