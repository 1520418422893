import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import NotificationSubscriptionApiUrls from 'src/apiUrls/NotificationSubscriptionApiUrls'
import React from 'react'
import NotificationSubscriptionKeys from 'src/constants/locale/key/NotificationSubscription'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification);
  const fetchCustomLookup = useFetchCustomLookup();
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.NOTIFICATION_SUBSCRIPTION
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let notificationSubscription = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: notificationSubscription
      }
    )
    let ntfSubId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NTF_SUB_ID
    )
    let eventId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EVENT_ID
    )
    let partyId = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.PARTY_ID
    )
    let actionFor = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ACTION_FOR
    )
    let allUser = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ALL_USER
    )
    let ntfType = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.NTF_TYPE
    )
    let rulePath = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.RULE_PATH
    )
    let roles = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.ROLES
    )
    let emails = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EMAILS
    )
    let mobileNo = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.MOBILE_NO
    )
    let users = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.USERS
    )
    let templateCode = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.TEMPLATE_CODE
    )
    let eventName = translate(
      Namespace.NOTIFICATION_SUBSCRIPTION,
      NotificationSubscriptionKeys.EVENT_NAME
    )

    return {
      notificationSubscription,
      tableTitle,
      ntfSubId,
      eventId,
      partyId,
      actionFor,
      allUser,
      ntfType,
      rulePath,
      roles,
      emails,
      mobileNo,
      users,
      templateCode,
      eventName
    }
  }

  const columns = [
    /*{
      field: "id",
      title: translatedTextsObject.ntfSubId,
      type: "numeric",
    },*/
    {
      field: "eventId",
      title: 'Event',
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_NOTIFICATION_ORIGIN_URL}/notification/event/get`,
            { },
            'content',
            'eventDescription',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: "partyId",
      title: 'Party',
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            { },
            'content',
            'name',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: "actionFor",
      title: translatedTextsObject.actionFor,
    },
    /*{
      field: "allUser",
      title: translatedTextsObject.allUser,
      type: "boolean",
    },*/
    /*{
      field: "ntfType",
      title: 'Notification Type',
    },*/
    /*{
      field: "rulePath",
      title: translatedTextsObject.rulePath,
    },
    {
      field: "roles",
      title: translatedTextsObject.roles,
    },*/
    {
      field: "emailId",
      title: translatedTextsObject.emails,
    },
    /*{
      field: "mobileNo",
      title: translatedTextsObject.mobileNo,
    },*/
    {
      field: "userId",
      title: translatedTextsObject.users,
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
            { },
            'content',
            'loginId',
            'id',
            (error) => console.error(error)
        )
      }
    },
    /*{
      field: "templateCode",
      title: translatedTextsObject.templateCode,
    },*/
    /*{
      field: "eventName",
      title: translatedTextsObject.eventName,
    }*/
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: NotificationSubscriptionApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: NotificationSubscriptionApiUrls.EXPORT }}
              fetch={{ url: NotificationSubscriptionApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              tableConfigurationCode="NOTIFICATION_SUBSCRIPTION_TABLE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
