import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingVesselVoyageInfoApiUrls from 'src/apiUrls/ping/PingVesselVoyageInfoApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
    card: { CngSimpleCardHeader },
    table: { CngCrudTable, useDefaultNotification, DateTimeRangeFilter }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

    const { showNotification } = props
    const notification = useDefaultNotification(showNotification)

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_VESSEL_VOYAGE_INFO)
    let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

    const Labels = {

        tableTitle: tableTitle,

        // Fields
        vesselName: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        source: translate(Namespace.PING, PingFieldKeys.SOURCE),
        prevInVoyage: translate(Namespace.PING, PingFieldKeys.PREV_IN_VOYAGE),
        prevOutVoyage: translate(Namespace.PING, PingFieldKeys.PREV_OUT_VOYAGE),
        currInVoyage: translate(Namespace.PING, PingFieldKeys.CURR_IN_VOYAGE),
        currOutVoyage: translate(Namespace.PING, PingFieldKeys.CURR_OUT_VOYAGE),
        nextInVoyage: translate(Namespace.PING, PingFieldKeys.NEXT_IN_VOYAGE),
        nextOutVoyage: translate(Namespace.PING, PingFieldKeys.NEXT_OUT_VOYAGE),
        createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE),
        updatedDate: translate(Namespace.PING, PingFieldKeys.UPDATED_DATE)
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'vesselName', title: Labels.vesselName },
        { field: 'source', title: Labels.source },
        { field: 'prevInVoyage', title: Labels.prevInVoyage },
        { field: 'prevOutVoyage', title: Labels.prevOutVoyage },
        { field: 'currInVoyage', title: Labels.currInVoyage },
        { field: 'currOutVoyage', title: Labels.currOutVoyage },
        { field: 'nextInVoyage', title: Labels.nextInVoyage },
        { field: 'nextOutVoyage', title: Labels.nextOutVoyage },
        {
            field: 'createdDate', title: Labels.createdDate,
            type: "datetime", filterComponent: DateTimeRangeFilter
        },
        {
            field: 'updatedDate', title: Labels.updatedDate,
            type: "datetime", filterComponent: DateTimeRangeFilter,
            defaultSort: 'desc'
        }
    ];

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CngSimpleCardHeader title={Labels.tableTitle} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CngCrudTable {...props}
                            notification={notification}

                            // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: PingVesselVoyageInfoApiUrls.GET }}
                            idAccessor='id'
                            columns={columns}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
