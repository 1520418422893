import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation, Yup } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import PingEventHubLogsApiUrls from 'src/apiUrls/ping/PingEventHubLogsApiUrls'

const {
	form: { field: { CngTextField, CngDateTimeField, CngLookupAutocompleteField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	remarks: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
	return Yup.object({
	})
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.PING)

	const PingFieldKeys = PingKeys.FIELDS

	const Labels = {

		// Fields
		containerNum: translate(Namespace.PING, PingFieldKeys.CONTAINER_NUM),
		vesselName: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
		createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE),
		apiKey: translate(Namespace.PING, PingFieldKeys.API_KEY),
		party: translate(Namespace.PING, PingFieldKeys.PARTY),
		userLoginId: translate(Namespace.PING, PingFieldKeys.USER_LOGIN_ID),
		requestSummary: translate(Namespace.PING, PingFieldKeys.REQUEST_SUMMARY),
		requestHeader: translate(Namespace.PING, PingFieldKeys.REQUEST_HEADER),
		requestBody: translate(Namespace.PING, PingFieldKeys.REQUEST_BODY),
		responseSummary: translate(Namespace.PING, PingFieldKeys.RESPONSE_SUMMARY),
		responseStatus: translate(Namespace.PING, PingFieldKeys.RESPONSE_STATUS),
		responseHeader: translate(Namespace.PING, PingFieldKeys.RESPONSE_HEADER),
		responseBody: translate(Namespace.PING, PingFieldKeys.RESPONSE_BODY),
		debugLog: translate(Namespace.PING, PingFieldKeys.DEBUG_LOG),
		remarks: translate(Namespace.PING, PingFieldKeys.REMARKS)
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.containerNum}>
				<CngTextField name="containerNum"
					label={Labels.containerNum}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.vesselName}>
				<CngTextField name="vesselName"
					label={Labels.vesselName}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.createdDate}>
				<CngDateTimeField name="createdDate"
					label={Labels.createdDate}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.userLoginId}>
				<CngTextField name="userLoginId"
					label={Labels.userLoginId}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.party}>
				<CngLookupAutocompleteField name="partyId"
					label={Labels.party}
					disabled={disabled}
					lookupProps={{
						url: PingEventHubLogsApiUrls.TPR_PARTY_GET,
						label: 'name',
						value: 'id'
					}}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={4} shouldHide={shouldHideMap.apiKey}>
				<CngTextField name="apiKeyUsed"
					label={Labels.apiKey}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.requestSummary}>
				<CngTextField name="requestSummary"
					label={Labels.requestSummary}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.requestHeader}>
				<CngTextField name="requestHeader"
					label={Labels.requestHeader}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.requestBody}>
				<CngTextField name="requestBody"
					label={Labels.requestBody}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={9} shouldHide={shouldHideMap.responseSummary}>
				<CngTextField name="responseSummary"
					label={Labels.responseSummary}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} md={3} shouldHide={shouldHideMap.responseStatus}>
				<CngTextField name="responseStatus"
					label={Labels.responseStatus}
					disabled={disabled}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.responseHeader}>
				<CngTextField name="responseHeader"
					label={Labels.responseHeader}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.responseBody}>
				<CngTextField name="responseBody"
					label={Labels.responseBody}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.debugLog}>
				<CngTextField name="debugLog"
					label={Labels.debugLog}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>

			<CngGridItem xs={12} shouldHide={shouldHideMap.remarks}>
				<CngTextField name="remarks"
					label={Labels.remarks}
					disabled={disabled}
					multiline={true}
				/>
			</CngGridItem>
		</Grid>

		//* End JSX ------------------------------------------------------------
	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
