import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingEventHubLogsApiUrls from 'src/apiUrls/ping/PingEventHubLogsApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
    table: { CngCrudTable, DateTimeRangeFilter, useFetchCustomLookup, useDefaultNotification }
} = components

function TablePage(props) {

    const { showNotification } = props
    const fetchCustomLookup = useFetchCustomLookup()

    // Translations ------------------------------------------------------------
    const { translate } = useTranslation(Namespace.PING)

    const PingFieldKeys = PingKeys.FIELDS

    const Labels = {

        // Columns
        containerNum: translate(Namespace.PING, PingFieldKeys.CONTAINER_NUM),
        vesselName: translate(Namespace.PING, PingFieldKeys.VESSEL_NAME),
        bookingId: translate(Namespace.PING, PingFieldKeys.BOOKING_ID),
        shippingInfoId: translate(Namespace.PING, PingFieldKeys.SI_ID),
        party: translate(Namespace.PING, PingFieldKeys.PARTY),
        apiKey: translate(Namespace.PING, PingFieldKeys.API_KEY),
        createdDate: translate(Namespace.PING, PingFieldKeys.CREATED_DATE)
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        {
            field: 'partyId', title: Labels.party,
            customLookup: () => {
                return fetchCustomLookup(
                    PingEventHubLogsApiUrls.TPR_PARTY_GET,
                    {},
                    'content',
                    'name',
                    'id',
                    (errorMessage) => console.error(errorMessage)
                )
            }
        },
        {
            field: 'apiKeyUsed', title: Labels.apiKey,
            lookup: {
                CALISTA: 'Calista',
                FORWARD_HUB: 'Forward Hub'
            }
        },
        { field: 'containerNum', title: Labels.containerNum },
        { field: 'vesselName', title: Labels.vesselName },
        { field: 'bookingId', title: Labels.bookingId, type: 'numeric' },
        { field: 'shippingInfoId', title: Labels.shippingInfoId, type: 'numeric' },
        {
            field: 'createdDate', title: Labels.createdDate,
            type: "datetime", defaultSort: 'desc',
            filterComponent: DateTimeRangeFilter
        }
    ];

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CngCrudTable {...props}
                    notification={showNotification}

                    // table fetch and data details
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    fetch={{ url: PingEventHubLogsApiUrls.SUBSCRIPTION_GET }}
                    idAccessor='id'
                    columns={columns}
                />
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
