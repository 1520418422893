import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import BreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Ping'
import PingEventSourceTablePage from './PingEventSource_TablePage'
import PingEventSourceViewPage from './PingEventSource_ViewPage'
import PingEventSourceEditPage from './PingEventSource_EditPage'
import PingEventSourceAddPage from './PingEventSource_AddPage'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
	const { translate } = useTranslation(Namespace.PING)
	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_ADMIN)

	return (title);
}

//* Wrapped Table Page ---------------------------------------------------------
function WrappedTablePage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<PingEventSourceTablePage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<PingEventSourceViewPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Edit Page ----------------------------------------------------------
function WrappedEditPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<PingEventSourceEditPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Add Page ----------------------------------------------------------
function WrappedAddPage(props) {
	const breadcrumbNameMap = BreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<PingEventSourceAddPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

export {
	WrappedTablePage as TablePage,
	WrappedViewPage as ViewPage,
	WrappedEditPage as EditPage,
	WrappedAddPage as AddPage
}