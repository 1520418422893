import {
  constants,
  Yup
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  return Yup.object({
    ntfSubRuleId: Yup.number(),
    ntfSubRuleName: Yup.string(),
    ruleFilter: Yup.string(),
    ruleValue: Yup.string(),
  })
}

export default makeValidationSchema
