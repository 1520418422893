import React from 'react'
import { Card, Divider, CardContent, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingShipmentEventApiUrls from 'src/apiUrls/ping/PingShipmentEventApiUrls'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification, useFetchCustomLookup, DateTimeRangeFilter }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)
	const { translate } = useTranslation([
		Namespace.UI_COMPONENT, Namespace.PING
	])
	const translatedTextsObject = makeTranslatedTextsObject()
	const fetchCustomLookup = useFetchCustomLookup();

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SHIPMENT_EVENT)

		let tableTitle = translate(
			Namespace.UI_COMPONENT,
			UiComponentKeys.Table.TITLE,
			{ nameTitleised: title }
		)

		// columns
		let eventId = translate(Namespace.PING, PingKeys.FIELDS.EVENT_ID)
		let eventGuid = translate(Namespace.PING, PingKeys.FIELDS.EVENT_GUID)
		let eventDate = translate(Namespace.PING, PingKeys.FIELDS.EVENT_DATE)
		let eventCode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_CODE)
		let eventLocode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_LOCODE)
		let eventSourceCode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_SOURCE_CODE)

		let referenceType = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_TYPE)
		let referenceId = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_ID)
		let referenceValue = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_VALUE)

		let createdDate = translate(Namespace.PING, PingKeys.FIELDS.CREATED_DATE)

		return {
			tableTitle,
			eventId,
			eventGuid,
			eventDate,
			eventCode,
			eventLocode,
			eventSourceCode,
			referenceType,
			referenceId,
			referenceValue,
			createdDate
		}
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'id', title: translatedTextsObject.eventId, type: 'numeric' },
		{ field: 'eventGuid', title: translatedTextsObject.eventGuid, sorting: false },
		{
			field: 'eventDate', title: translatedTextsObject.eventDate,
			type: 'datetime', defaultSort: 'desc',
			filterComponent: DateTimeRangeFilter
		},
		{ field: 'eventCode', title: translatedTextsObject.eventCode },
		{ field: 'eventLocode', title: translatedTextsObject.eventLocode },
		{
			field: 'eventSourceCode', title: translatedTextsObject.eventSourceCode,
			customLookup: () => {

				let reqBody = {
					customData: {
						"baseFilterDTO": {
							"filterType": "AND",
							"filterProperties": [
								{ "fieldName": "code", "operatorType": "NOT_EQUAL", "value1": "ALL" }
							],
							"sortProperties": [
								{ "sortField": "code", "sortType": "ASC" }
							]
						}
					}
				}


				return fetchCustomLookup(
					PingEventSourceApiUrls.GET,
					reqBody,
					'content',
					'code',
					'code'
				)
			}
		},
		{
			field: 'referenceType', title: translatedTextsObject.referenceType,
			lookup: {
				FREIGHT_BOOKING_ID: "FREIGHT_BOOKING_ID",
				FREIGHT_BOOKING_CARRIER_REF: "FREIGHT_BOOKING_CARRIER_REF",
				FREIGHT_SI_ID: "FREIGHT_SI_ID",
				FREIGHT_BL_NUM: "FREIGHT_BL_NUM",
				CONTAINER_NUM: "CONTAINER_NUM",
				BARGE_BOOKING_ID: "BARGE_BOOKING_ID",
				BARGE_BOOKING_REF: "BARGE_BOOKING_REF",
				FREIGHT_BOOKING_DOC_ID: "FREIGHT_BOOKING_DOC_ID",
				FREIGHT_SI_DOC_ID: "FREIGHT_SI_DOC_ID",
				FREIGHT_SI_DG_DOC_ID: "FREIGHT_SI_DG_DOC_ID",
				VESSEL_NAME: "VESSEL_NAME"
			},
		},
		{ field: 'referenceId', title: translatedTextsObject.referenceId, type: 'numeric', sorting: false },
		{ field: 'referenceValue', title: translatedTextsObject.referenceValue },
		{
			field: 'createdDate', title: translatedTextsObject.createdDate,
			type: 'datetime',
			filterComponent: DateTimeRangeFilter
		}
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingShipmentEventApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// add route
							addRoute={`${pathname}/add`}

							// edit route
							editRoute={`${pathname}/edit`}

							// delete
							del={{ url: PingShipmentEventApiUrls.DELETE }}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
