import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

	return Yup.object({
		eventCode: Yup.string().max(10),
		eventLocode: Yup.string().max(10)
	})
}

export default makeValidationSchema