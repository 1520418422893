import {
  constants,
  Yup
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  return Yup.object({
    eventId: Yup.string(),
    firstOption: Yup.string(),
    secondOption: Yup.string(),
    thirdOption: Yup.string(),
    forthOption: Yup.string(),
    fifthOption: Yup.string(),
  })
}

export default makeValidationSchema
