import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingKeys from 'src/constants/locale/key/Ping'
import makeValidationSchema from './PingShipmentEvent_MakeValidationSchema'

import PingShipmentEventApiUrls from 'src/apiUrls/ping/PingShipmentEventApiUrls'
import PingEventSourceApiUrls from 'src/apiUrls/ping/PingEventSourceApiUrls'

const {
	card: { CngSimpleCardHeader },
	form: { field: { CngTextField, CngSelectField, CngSwitchField, CngLookupAutocompleteField, CngDateTimeField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	eventId: "(System Generated)",
	eventGuid: "(System Generated)",
	eventDate: null,
	eventCode: "",
	eventLocode: "",
	eventSourceCode: "CALISTA",
	referenceType: "",
	referenceId: null,
	referenceValue: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.PING)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let eventDate = translate(Namespace.PING, PingKeys.FIELDS.EVENT_DATE)
		let eventCode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_CODE)
		let eventLocode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_LOCODE)
		let eventSourceCode = translate(Namespace.PING, PingKeys.FIELDS.EVENT_SOURCE_CODE)
		let referenceType = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_TYPE)
		let referenceId = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_ID)
		let referenceValue = translate(Namespace.PING, PingKeys.FIELDS.REFERENCE_VALUE)

		return {
			eventDate,
			eventCode,
			eventLocode,
			eventSourceCode,
			referenceType,
			referenceId,
			referenceValue
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.eventDate}>
				<CngDateTimeField name="eventDate"
					label={translatedTextsObject.eventDate}
					disabled={disabled}
					required
				/>
			</CngGridItem>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.eventCode}>
				<CngTextField name="eventCode"
					label={translatedTextsObject.eventCode}
					disabled={disabled}
					required
				/>
			</CngGridItem>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.eventLocode}>
				<CngTextField name="eventLocode"
					label={translatedTextsObject.eventLocode}
					disabled={disabled}
					required
				/>
			</CngGridItem>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.eventSourceCode}>
				<CngLookupAutocompleteField name="eventSourceCode"
					label={translatedTextsObject.eventSourceCode}
					disabled={disabled}
					required
					lookupProps={{
						url: PingEventSourceApiUrls.GET,
						label: 'code',
						value: 'code'
					}}
				/>
			</CngGridItem>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.referenceType}>
				<CngSelectField name="referenceType"
					label={translatedTextsObject.referenceType}
					disabled={disabled}
					required
					items={[
						{ text: 'FREIGHT_BOOKING_ID', value: 'FREIGHT_BOOKING_ID' },
						{ text: 'FREIGHT_BOOKING_CARRIER_REF', value: 'FREIGHT_BOOKING_CARRIER_REF' },
						{ text: 'FREIGHT_SI_ID', value: 'FREIGHT_SI_ID' },
						{ text: 'FREIGHT_BL_NUM', value: 'FREIGHT_BL_NUM' },
						{ text: 'CONTAINER_NUM', value: 'CONTAINER_NUM' },
						{ text: 'BARGE_BOOKING_ID', value: 'BARGE_BOOKING_ID' },
						{ text: 'BARGE_BOOKING_REF', value: 'BARGE_BOOKING_REF' },
						{ text: 'FREIGHT_BOOKING_DOC_ID', value: 'FREIGHT_BOOKING_DOC_ID' },
						{ text: 'FREIGHT_SI_DOC_ID', value: 'FREIGHT_SI_DOC_ID' },
						{ text: 'FREIGHT_SI_DG_DOC_ID', value: 'FREIGHT_SI_DG_DOC_ID' }
					]}
				/>
			</CngGridItem>

			<CngGridItem xs={6} sm={3} shouldHide={shouldHideMap.referenceId}>
				<CngTextField name="referenceId"
					label={translatedTextsObject.referenceId}
					disabled={disabled}
					type="number"
				/>
			</CngGridItem>

			<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.referenceValue}>
				<CngTextField name="referenceValue"
					label={translatedTextsObject.referenceValue}
					disabled={disabled}
				/>
			</CngGridItem>

		</Grid>
		//* End JSX ------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
