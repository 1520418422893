import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import PingSysConfigApiUrls from 'src/apiUrls/ping/PingSysConfigApiUrls'
import PingKeys from 'src/constants/locale/key/Ping'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)

	// Translations ------------------------------------------------------------
	const { translate } = useTranslation(Namespace.UI_COMPONENT, Namespace.PING)

	let PingFieldKeys = PingKeys.FIELDS

	let title = translate(Namespace.PING, PingKeys.SUB_MODULE_TITLE.PING_SYS_CONFIG)
	let tableTitle = translate(Namespace.UI_COMPONENT, UiComponentKeys.Table.TITLE, { nameTitleised: title })

	const Labels = {
		TABLE_TITLE: tableTitle,
		GROUP: translate(Namespace.PING, PingFieldKeys.GROUP),
		DISPLAY_NAME: translate(Namespace.PING, PingFieldKeys.DISPLAY_NAME),
		VALUE: translate(Namespace.PING, PingFieldKeys.VALUE),
		REMARKS: translate(Namespace.PING, PingFieldKeys.REMARKS)
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{
			field: 'groupName', title: Labels.GROUP,
			lookup: {
				"Ping Search": "Ping Search",
				"Ping Milestone": "Ping Milestone",
				"Event Hub": "Event Hub",
				"PortNet": "PortNet"
			},
		},
		{ field: 'displayName', title: Labels.DISPLAY_NAME },
		{ field: 'value', title: Labels.VALUE, sorting: false },
		{ field: 'remarks', title: Labels.REMARKS, sorting: false }
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={Labels.TABLE_TITLE} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: PingSysConfigApiUrls.GET }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// edit route
							editRoute={`${pathname}/edit`}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
