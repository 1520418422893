import React from 'react'

function Placeholder({ showNotification }) {

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <div>
            Work in progress...
        </div>
        //* End JSX ------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default Placeholder