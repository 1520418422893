import React from 'react'
import { Card, CardContent } from '@material-ui/core'

import WarningAlert from 'src/components/alert/WarningAlert'
import InfoAlert from 'src/components/alert/InfoAlert'

function SummaryPage(props) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>
                <WarningAlert title="Objective">
                    <p>
                        This is a temporary UI feature for monitoring the migration progress
                        <br />- from Calista CurrentGen (CG) to Calista Nextgen (NG).
                    </p>
                    <p>This UI, alongside with all relevant codes, will be deleted once the migration is completed and verified.</p>
                </WarningAlert>

                <InfoAlert title="CG Raw Data">
                    <p>This tab contains the RAW data cloned from CG database.</p>
                    <ul style={{ marginLeft: "2em" }}>
                        <li><b>Booking Milestones</b> = TCAL_BOOKING_MILESTONE</li>
                        <li><b>Booking Info</b> = TCAL_BOOKING</li>
                        <li><b>Shipping Info</b> = TCAL_BOOKING_SHIPPING_INFO</li>
                    </ul>
                </InfoAlert>

                <InfoAlert title="Milestone Migration Logs">
                    <p>This tab contains the logs for the milestone migration batch job.</p>
                    <ul style={{ marginLeft: "2em" }}>
                        <li><b>Migration Queue</b> - Records queue for processing.</li>
                        <li><b>Batch Log</b> - Debug logs for the batch job.</li>
                        <li><b>Migration Log</b> - Debug logs for each migrated records.</li>
                    </ul>
                </InfoAlert>

                <InfoAlert title="ID Patch Logs">
                    <p>This tab contains the logs for the ID patching batch job.</p>
                    <ul style={{ marginLeft: "2em" }}>
                        <li><b>Batch Log</b> - Debug logs for the batch job.</li>
                        <li><b>Patch Log</b> - Debug logs for each event records processed.</li>
                    </ul>
                </InfoAlert>
            </CardContent>
        </Card>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default SummaryPage