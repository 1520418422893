import React from 'react'
import { Grid } from '@material-ui/core'
import { components, Yup } from 'cng-web-lib'

const {
    form: { field: { CngTextField, CngDateTimeField } },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    remarks: ""
})

//* Front end validations ------------------------------------------------------
function makeValidationSchema(translate) {
    return Yup.object({
    })
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

    // Translations ------------------------------------------------------------
    // This is just a temporary feature. Translation not implemented.

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={3}>

            <CngGridItem xs={12} md={6}>
                <CngTextField name="id"
                    label='ID'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngDateTimeField name="createdDate"
                    label='Created Date'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={3}>
                <CngTextField name="bookingMilestoneId"
                    label='Milestone ID'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={3}>
                <CngTextField name="shipmentEventId"
                    label='Shipment Event ID'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} md={6}>
                <CngTextField name="shipmentEventGuid"
                    label='Shipment Event GUID'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12}>
                <CngTextField name="summary"
                    label='Summary'
                    disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12}>
                <CngTextField name="debugLog"
                    label='Debug Log'
                    disabled={disabled}
                    multiline={true}
                />
            </CngGridItem>
        </Grid>

        //* End JSX ------------------------------------------------------------
    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    let localData = serverData;
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
